import * as React from "react";
import styled from "styled-components/macro";
import { BreadcrumbModel } from "../models/breadcrumbs.model";
import Anchor from "./Anchor";
import DivFlex from "./DivFlex";
import DivMargin from "./DivMargin";
import { useBreakpoints } from "../hooks/useBreakpoints";
import SpanRobotoFont from "./SpanRobotoFont";

interface Props {
  color: string;
  hoverColor: string;
  deadColor: string;
  breadcrumbs: BreadcrumbModel[];
  currentPage: string;
}

const Breadcrumbs: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  return (
    <React.Fragment>
      <DivMargin bottom={30} top={10}>
        <DivFlex justify={"flex-start"} align={"center"}>
          {props.breadcrumbs.map((crumb, index) => {
            return (
              <DivBreadCrumbsContainer
                isMobile={!breakpoints.isMd}
                key={crumb.label}
              >
                <AnchorBreadCrumb
                  color={props.color}
                  hoverColor={props.hoverColor}
                  isMobile={!breakpoints.isSm}
                  href={crumb.href}
                >
                  {crumb.label}
                </AnchorBreadCrumb>
                <SpanRobotoFont
                  color={props.deadColor}
                  weight={"regular"}
                  size={!breakpoints.isSm ? "15px" : "20px"}
                >
                  /
                </SpanRobotoFont>
              </DivBreadCrumbsContainer>
            );
          })}
          <SpanRobotoFont
            color={props.deadColor}
            size={!breakpoints.isSm ? "15px" : "20px"}
            weight={"regular"}
          >
            {props.currentPage}
          </SpanRobotoFont>
        </DivFlex>
      </DivMargin>
    </React.Fragment>
  );
};

export const DivBreadCrumbsContainer = styled.div<{ isMobile: boolean }>`
  span {
    margin: 0 ${(props) => (props.isMobile ? 10 : 15)}px;
  }
  a {
    -webkit-font-smoothing: antialiased;
  }
  span {
    -webkit-font-smoothing: antialiased;
  }
`;

export const AnchorBreadCrumb = styled(Anchor)<{ isMobile: boolean }>`
  font-size: ${(props) => (props.isMobile ? 15 : 20)}px;
`;

export default Breadcrumbs;
