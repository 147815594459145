import React, { useMemo } from "react";
import SpanRobotoFont from "./SpanRobotoFont";
import { WorkListItemModel } from "../models/workItem.model";
import Palette from "../palette";
import Button from "./Button";
import DivFlex from "./DivFlex";
import DivMargin from "./DivMargin";
import styled from "styled-components/macro";
import DivCard from "./DivCard";
import DivPadding from "./DivPadding";
import Waypoint from "./Waypoint";
import { Col, Row } from "react-grid-system";
import DivFixed from "./DivFixed";
import SpanPlayfairFont from "./SpanPlayfairFont";
import { BreakpointsModel, useBreakpoints } from "../hooks/useBreakpoints";
import AnchorNoStyle from "./AnchorNoStyle";

interface Props {
  index: number;
  item: WorkListItemModel;
}

const WorkListCard: React.FC<Props> = (props: Props): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const breakpoints = useBreakpoints();

  const fadeUpIn = {
    hidden: {
      opacity: 0,
      translateY: "25px",
    },
    visible: {
      opacity: 1,
      translateY: "0px",
      transition: {
        type: "tween",
        delay: 0.2 * props.index,
        duration: 0.5,
      },
    },
  };

  const isAbsolute = useMemo(() => /http(s?):\/\//.test(props.item.href), [
    props.item.href,
  ]);

  return (
    <Waypoint onEnter={() => setVisible(true)}>
      <AnchorNoStyle
        href={props.item.href}
        target={isAbsolute ? "_blank" : "_self"}
      >
        <DivContainer
          className={"work-card"}
          breakpoints={breakpoints}
          variants={fadeUpIn}
          initial={"hidden"}
          animate={visible ? "visible" : "hidden"}
        >
          <Row>
            <Col>
              <DivImg src={props.item.imgSrc} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DivFixed height={"225px"}>
                <DivPadding padding={20}>
                  <Row align={"center"}>
                    <Col xs={9}>
                      <SpanRobotoFont
                        color={Palette.white}
                        size={"14px"}
                        weight={"light"}
                      >
                        {props.item.techs}
                      </SpanRobotoFont>
                    </Col>
                    <Col xs={3}>
                      <DivFlex justify={"flex-end"}>
                        <SpanRobotoFont
                          color={Palette.secondary}
                          size={"20px"}
                          weight={"bold"}
                        >
                          {props.item.year}
                        </SpanRobotoFont>
                      </DivFlex>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DivMargin top={25}>
                        <DivFlex align={"flex-end"}>
                          <DivHighlight />
                          <SpanPlayfairFont color={Palette.white} size={"22px"}>
                            {props.item.name}
                          </SpanPlayfairFont>
                        </DivFlex>
                      </DivMargin>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DivMargin top={25}>
                        <DivFixed width={"80%"}>
                          <SpanRobotoFont color={Palette.white} size={"15px"}>
                            {props.item.description}
                          </SpanRobotoFont>
                        </DivFixed>
                      </DivMargin>
                    </Col>
                  </Row>
                </DivPadding>
              </DivFixed>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivFixed height={"75"}>
                <DivFlex justify={"center"} align={"flex-end"}>
                  <Button className={"explore-button"}>Learn More</Button>
                </DivFlex>
              </DivFixed>
            </Col>
          </Row>
        </DivContainer>
      </AnchorNoStyle>
    </Waypoint>
  );
};

const DivContainer = styled(DivCard)<{ breakpoints: BreakpointsModel }>`
  backface-visibility: hidden;
  user-select: none;
  overflow: hidden;
  padding: 0 0 40px;
  transition: all 500ms ease-out;
  cursor: pointer;

  ${(props) =>
    props.breakpoints.isLg
      ? `
  &:hover {
    background-color: ${Palette.bgCardLight};
    transition: all 100ms ease-in;
    transform: translateY(-10px);
    z-index: 2;

    .explore-button {
      transition: all 100ms ease-in;
      transform: translateY(0px);
      opacity: 1;
    }
  }
  `
      : undefined}

  .explore-button {
    margin-top: 10px;
    transition: all 500ms ease-out;
    transform: translateY(${(props) => (!props.breakpoints.isLg ? 0 : 10)}px);
    opacity: ${(props) => (!props.breakpoints.isLg ? 1 : 0)};
  }

  @media (min-width: 1px) and (max-width: 767px) {
    margin: 0 10px;
  }
`;

const DivImg = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  height: 300px;
`;

const DivHighlight = styled.div`
  background-color: ${Palette.tertiary};
  width: 3px;
  height: 15px;
  margin-right: 5px;
`;

export default WorkListCard;
