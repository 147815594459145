import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components/macro";

interface Props {}

const TintedWindows: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <DivContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.8 }}
      transition={{ duration: 0.25 }}
    >
      <svg viewBox="0 0 500 500" preserveAspectRatio="none" className={"rgl"}>
        <defs>
          <radialGradient
            id={"rgl"}
            cx="0"
            cy="1"
            r="1.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="rgba(40,35,51,0)" />
            <stop offset="0.65" stopColor="rgba(40,35,51,0)" />
            <stop offset="0.85" stopColor="rgba(40,35,51,0.95)" />
          </radialGradient>
        </defs>
        <rect width="500" height="500" fill="url(#rgl)" />
      </svg>
      <svg viewBox="0 0 500 500" preserveAspectRatio="none" className="rgr">
        <defs>
          <radialGradient
            id="rgr"
            cx="0"
            cy="1"
            r="1.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="rgba(40,35,51,0)" />
            <stop offset="0.65" stopColor="rgba(40,35,51,0)" />
            <stop offset="0.85" stopColor="rgba(40,35,51,0.95)" />
          </radialGradient>
        </defs>
        <rect width="500" height="500" fill="url(#rgr)" />
      </svg>
    </DivContainer>
  );
};

const DivContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 500px;
  pointer-events: none;

  svg {
    position: fixed;
    width: 100vw;
    height: 500px;
    pointer-events: none;
  }

  .rgr {
    transform: scaleX(-1);
  }
`;

export default TintedWindows;
