import React from "react";
import styled from "styled-components/macro";

const SuccessIcon: React.FC = (): JSX.Element => {
  return (
    <DivContainer>
      <div className="success-icon">
        <div className="success-icon__tip" />
        <div className="success-icon__long" />
      </div>
    </DivContainer>
  );
};

const DivContainer = styled.div`
  align-items: center;
  justify-content: center;

  // Structure
  .success-icon {
    display: inline-block;
    width: 8em;
    height: 8em;
    font-size: 10px;
    border-radius: 50%;
    border: 4px solid #6fb369;
    background-color: #454545;
    position: relative;
    overflow: hidden;
    transform-origin: center;
    animation: showSuccess 180ms ease-in-out;
    transform: scale(1);
  }

  // Elements
  .success-icon {
    &__tip,
    &__long {
      display: block;
      position: absolute;
      height: 4px;
      background-color: #6fb369;
      border-radius: 10px;
    }

    &__tip {
      width: 2.4em;
      top: 4.3em;
      left: 1.4em;
      transform: rotate(45deg);
      animation: tipInPlace 300ms ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 180ms;
      visibility: hidden;
    }

    &__long {
      width: 4em;
      transform: rotate(-45deg);
      top: 3.7em;
      left: 2.75em;
      animation: longInPlace 140ms ease-in-out;
      animation-fill-mode: forwards;
      visibility: hidden;
      animation-delay: 300ms + 140ms;
    }
  }

  @keyframes showSuccess {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes tipInPlace {
    from {
      width: 0em;
      top: 0em;
      left: -1.6em;
    }
    to {
      width: 2.4em;
      top: 4.3em;
      left: 1.4em;
      visibility: visible;
    }
  }

  @keyframes longInPlace {
    from {
      width: 0em;
      top: 5.1em;
      left: 3.2em;
    }
    to {
      width: 4em;
      top: 3.7em;
      left: 2.75em;
      visibility: visible;
    }
  }
`;

export default SuccessIcon;
