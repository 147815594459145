import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { useBreakpoints } from "../hooks/useBreakpoints";

interface Props {
  color: string;
  started: boolean;
}

const TylerBurkeAnimatedSvg: React.FC<Props> = (props: Props): JSX.Element => {
  const [started, setStarted] = React.useState(false);
  const breakpoints = useBreakpoints();

  const calcHeight = (): number => {
    if (breakpoints.isLg) {
      return 200;
    } else if (breakpoints.isMd) {
      return 142;
    } else {
      return 70;
    }
  };

  const calcWidth = (): number => {
    if (breakpoints.isLg) {
      return 700;
    } else if (breakpoints.isMd) {
      return 500;
    } else {
      return 245;
    }
  };

  useEffect(() => {
    if (props.started && !started) {
      setStarted(true);
    } else if (!props.started && started) {
      setStarted(false);
    }
  }, [props.started, started]);

  return (
    <DivContainer width={calcWidth()} height={calcHeight()} color={props.color}>
      {started && (
        <svg id="Text" viewBox="0 0 564.39 123.425">
          <defs>
            <clipPath id="tbanim-cp" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M19.8,96.4q0-13.5,8-26.75-9.252-5.874-9.25-17.375,0-13.875,12.25-24.625Q44.553,15.521,66.3,15.52A88.963,88.963,0,0,1,87.8,18.4q2.625.626,22.375,9.687t28.375,9.063q5.748,0,8.625-3.25a5.082,5.082,0,0,0,1.5-3.5,4.511,4.511,0,0,0-1.625-3.375,4.33,4.33,0,0,1-.875-1.625q0-.375.688-.375t2.375,2.187a8.153,8.153,0,0,1,1.687,5.125q0,2.938-3.937,5.625a17.234,17.234,0,0,1-9.938,2.688q-9.375,0-29.125-9.063T83.117,21.52a60.13,60.13,0,0,0-11.563-1q-25.875,0-40.75,16.75-1.752,1.875-4.125,7.562A28.338,28.338,0,0,0,24.3,55.77q0,7.251,5.125,11.25A53.033,53.033,0,0,1,44.8,51.9a33.3,33.3,0,0,1,18-5.625q11.25,0,11.25,8.75,0,10.5-12.312,22.812T38.929,90.145q-6.75,0-6.75-5.75a5.9,5.9,0,0,1,1.063-3.5A2.3,2.3,0,0,1,35.8,79.77l-.25,2.875q0,1.875,5.75,1.875,8.5,0,17.938-8.75T70.679,58.645a13.3,13.3,0,0,0,.5-3.375q0-5.625-7.187-5.625t-14.875,5.5a67.072,67.072,0,0,0-14.438,14.5,29.577,29.577,0,0,0,3.875,1l7.5.25q5.5.251,5.5,1.25,0,1.751-5.25,1.75h-.75a35.268,35.268,0,0,1-12.5-1.875q-9.627,14.751-9.625,27.125,0,16,12.375,16,14,0,29.125-19.5l24.5-36.5q13.749-20,23.875-20.5l4.75,1.125a4.04,4.04,0,0,1,2.25,3.75q0,2.5-1.25,2.5l-4-.625q-8.625,0-22.125,18.625L68.054,98.77q-16,18.624-30.625,18.625-10,0-13.812-5.375T19.8,96.4Z"
              />
            </clipPath>
            <clipPath id="tbanim-cp-2" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M92.726,121.691q6,7.5,10.375,7.5,7.125,0,15.375-11.375a124.224,124.224,0,0,0,11.75-21.125l4.75-11.5L116.6,99.566q-8.5,6.627-13.625,6.625a5.5,5.5,0,0,1-4.375-1.875,6.319,6.319,0,0,1-1.625-4.25q0-5.373,9.25-17.687t13.5-12.313q4.248,0,4.25,2.875,0,1.125-11.062,12.938t-11.063,15.937q0,1.626,2.125,1.625,4,0,16.063-9.375a203.5,203.5,0,0,0,18.187-15.5q1.374-1.623,3.875-4.875a6.887,6.887,0,0,1,5.25-2.75q2.874,0,2.875,2.75,0,.5-5.625,8.875t-5.875,8.875q-3.876,7.5-11.875,21.875-12.126,19.5-24.25,19.5a9.051,9.051,0,0,1-7-3.437A10.9,10.9,0,0,1,92.726,121.691Z"
              />
            </clipPath>
            <clipPath id="tbanim-cp-3" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M150.851,100.941q0,3.75,4.125,3.75t10.625-4.375a59.47,59.47,0,0,0,12.625-11.875q1.5-2,2.375-2c.582,0,.875.25.875.75s-1.709,2.668-5.125,6.5q-12.5,13.752-20.875,13.75a12.223,12.223,0,0,1-7-1.937,6.724,6.724,0,0,1-2.875-6,26.212,26.212,0,0,1,2.125-9.438,166.488,166.488,0,0,1,11.625-21.375q14.625-23.748,20.75-23.75a4.424,4.424,0,0,1,2.562.938,3.031,3.031,0,0,1,1.438,2.062q-2.877,3.876-11.063,14.875T161.726,78.629q-3.126,4.813-4.688,7.187T154.726,89.5q-.75,1.313-1.75,3.437A17.951,17.951,0,0,0,150.851,100.941Z"
              />
            </clipPath>
            <clipPath id="tbanim-cp-4" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M182.725,108.816a7.867,7.867,0,0,1-2.5-.375q-8-2.25-8-9,0-8.748,11-20.25t19.75-11.5a6.931,6.931,0,0,1,4.688,1.563,4.856,4.856,0,0,1,1.812,3.812q0,7-10.562,13.375t-17.188,6.375a5.5,5.5,0,0,1-1.75-.25,14.457,14.457,0,0,0-1.875,7.125q0,6.5,6.188,6.5t16.062-8.375a79.867,79.867,0,0,0,13-13q1.125-1.5,1.938-1.5t.812.75a4.216,4.216,0,0,1-1,2.125,76.808,76.808,0,0,1-16.25,15.75Q189.349,108.817,182.725,108.816Zm23.625-36q0-1.5-3.187-1.5T194.1,76.191a55.8,55.8,0,0,0-12.25,13.625,5.069,5.069,0,0,0,1.625.25q4.749,0,12.688-5.062T206.1,73.941A3.079,3.079,0,0,0,206.35,72.816Z"
              />
            </clipPath>
            <clipPath id="tbanim-cp-5" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M233.974,107.816q-9.375,0-9.375-10.375a28.8,28.8,0,0,1,2.75-11.5,51.164,51.164,0,0,1,6-10.5,8.529,8.529,0,0,1-1.625.125q-3,0-5-4.25l-9.25,12q-7.752,9.627-9,9.625c-.416,0-.625-.166-.625-.5q0-1.248,2.125-3.625,10.875-12.624,15.375-21.375,2.874-10.875,7.25-10.875,2.874,0,2.875,2.5t-3.125,6.375l-3,4q0,2.625,3.875,2.625a10.023,10.023,0,0,0,5-1.625l3.25-2a3.684,3.684,0,0,1,1.25-.125,3.068,3.068,0,0,1,2.125,1.25,2.221,2.221,0,0,1,.75,2.25l-8.625,10.875q-8,10.251-8,16.188t5.5,5.937q5.748,0,14.125-6.125a60.6,60.6,0,0,0,9.125-8.125q3.873-4.5,4.25-4.5c.582,0,.875.313.875.938a3.545,3.545,0,0,1-1.125,2.187l-4,4.5a73.939,73.939,0,0,1-7.5,6.75Q240.348,107.818,233.974,107.816Z"
              />
            </clipPath>
            <clipPath id="tbanim-cp-6" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M378.723,83.566a18.768,18.768,0,0,1-2,8.5q-4.251,9-16.25,15a55.226,55.226,0,0,1-25,6q-19.752,0-26.875-13.5-12.876,10.752-18.375,10.75a3.862,3.862,0,0,1-1.75-.375q-3.75-2-3.75-8.625t5.5-16.25q5.5-9.624,9.625-11.375a9.97,9.97,0,0,1,2.875-.625q3.624,0,5.5,6l3.75,12a146.4,146.4,0,0,0,9.875-12.375l11.125-15q20.5-26.625,26.875-29.125a12.681,12.681,0,0,1,4.625-.875,7.184,7.184,0,0,1,3.937,1,2.924,2.924,0,0,1,1.563,2.5q0,2.25-3.625,2.625-3.75,2.127-14.313,12.438T339.473,65.316q-14,19.5-25.875,30,7.623,15.126,22.875,15.125,9.249,0,18.375-5.812a38.143,38.143,0,0,0,13.625-14.313,16.548,16.548,0,0,0,2-8.125,12.84,12.84,0,0,0-3.563-8.562,20.746,20.746,0,0,0-8.937-5.938q-2.5-.375-8-1.062t-5.5-2.313a1.554,1.554,0,0,1,.5-1.125,4.869,4.869,0,0,1,3.875-1.75,7.52,7.52,0,0,1,2.687.5q1.313.5,2.063.75c.5.168,1.082.375,1.75.625q2.748.252,4.75.25a41.075,41.075,0,0,0,15-2.625q8.625-3.375,11-10.125a24.568,24.568,0,0,0,1.5-7.875q0-8.625-8.375-12.875-7.251-3.75-19.875-3.75-19.875,0-35.625,7.5a52.4,52.4,0,0,0-15.188,11.125q-7.19,7.377-7.187,12.625a9.38,9.38,0,0,0,1.875,6,15.474,15.474,0,0,0,2.625,2.5q.624.375-.75.375-7.752-1.749-7.75-10.375t8.75-16.25a56.1,56.1,0,0,1,14.625-9.125q16.875-7.248,38.375-7.25,15.375,0,24.5,4.5,10.623,5,10.625,15.125a22.083,22.083,0,0,1-1.625,8.125q-2.625,7.5-13.625,11.375a55.765,55.765,0,0,1-17.75,3A18.3,18.3,0,0,1,373.66,71,17.057,17.057,0,0,1,378.723,83.566Zm-79.875-4.625a2.192,2.192,0,0,0-.625.25q-9.252,13.377-9.25,21.5a11.8,11.8,0,0,0,1.625,6.125,1.342,1.342,0,0,0,1.25.625q4,0,15.375-11.375l-.375-.5Q301.847,78.943,298.848,78.941Z"
              />
            </clipPath>
            <clipPath id="tbanim-cp-7" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M419.846,107.941a17.782,17.782,0,0,1-2.688.125,6.67,6.67,0,0,1-4.125-1.625,5.09,5.09,0,0,1-2.062-4.187,19.973,19.973,0,0,1,.937-5.688q.938-3.123,1.313-4.75-14.376,16.752-22.5,16.75-5.127,0-5.625-6.375v-.875q0-5.373,6.5-14.5a90.542,90.542,0,0,0-7.375,7.5,1.691,1.691,0,0,1-1.25.5c-.332,0-.5-.207-.5-.625a4.669,4.669,0,0,1,1.125-2.125l17.25-20.25q3.249-3.75,6.625-3.75t3.375,2.25a2.268,2.268,0,0,1-.75,1.625q-5,4.626-12.563,15.375t-7.562,15.875q0,2.25,1.5,2.25,5.25,0,15.25-10.375,3.5-3.5,13-15.437t12.5-11.938q4.5,0,4.5,3.5,0,1.251-5.25,7.25Q424.346,87.066,420.6,92.5t-3.75,8.812q0,3.375,1.875,3.375a5.015,5.015,0,0,0,2-.5q8.625-3.75,20.625-17.5a2.065,2.065,0,0,1,1.5-.875c.5,0,.75.272.75.813a2.907,2.907,0,0,1-.875,1.812A123.991,123.991,0,0,1,430.1,101Q422.845,107.318,419.846,107.941Z"
              />
            </clipPath>
            <clipPath id="tbanim-cp-8" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M463.469,107.816q-9.375,0-9.375-10.375a28.8,28.8,0,0,1,2.75-11.5,51.164,51.164,0,0,1,6-10.5,8.529,8.529,0,0,1-1.625.125q-3,0-5-4.25l-9.25,12q-7.752,9.627-9,9.625c-.416,0-.625-.166-.625-.5q0-1.248,2.125-3.625,10.875-12.624,15.375-21.375,2.874-10.875,7.25-10.875,2.874,0,2.875,2.5t-3.125,6.375l-3,4q0,2.625,3.875,2.625a10.023,10.023,0,0,0,5-1.625l3.25-2a3.684,3.684,0,0,1,1.25-.125,3.068,3.068,0,0,1,2.125,1.25,2.221,2.221,0,0,1,.75,2.25l-8.625,10.875q-8,10.251-8,16.188t5.5,5.937q5.748,0,14.125-6.125a60.6,60.6,0,0,0,9.125-8.125q3.873-4.5,4.25-4.5c.582,0,.875.313.875.938a3.545,3.545,0,0,1-1.125,2.187l-4,4.5a73.939,73.939,0,0,1-7.5,6.75Q469.843,107.818,463.469,107.816Z"
              />
            </clipPath>
            <clipPath id="tbanim-cp-9" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M512.844,108.691q-9.876,0-16.75-15.625-1.752,2.625-7.875,14.75-4.5-1.749-4.5-4.75,0-5.373,16.125-29.5t22.125-28.375a3.629,3.629,0,0,1,2-.375q3.873,0,3.875,2,0,1-8.688,11.25t-9.687,12.375q11.373-5.874,18.125-5.875a8.531,8.531,0,0,1,6.625,3.125,7.665,7.665,0,0,1,2.625,5.375v.75q-1,7.125-10,11.75-7.251,3.876-13.5,3.875t-8.75-1.625a1.214,1.214,0,0,0-1.188.375,1.2,1.2,0,0,0-.187,1.25q3.5,15.5,11.625,15.5,4.248,0,10.937-4.75a77.384,77.384,0,0,0,13.938-13.375,2.411,2.411,0,0,1,1.562-.875.609.609,0,0,1,.688.688,3.906,3.906,0,0,1-.875,1.937,86.172,86.172,0,0,1-12.75,13Q519.218,108.691,512.844,108.691Zm18-38.25a2.776,2.776,0,0,0-1.125-2,3.541,3.541,0,0,0-2.375-1q-6.75,0-16.188,5.625t-11.562,12.75q1.125,0,6,.375,5.625,0,14.75-4.625Q530.844,76.316,530.844,70.441Z"
              />
            </clipPath>
            <clipPath id="tbanim-cp-10" transform="translate(-15.843 -12.938)">
              <path
                className="tbanim-1"
                d="M542.843,108.816a7.867,7.867,0,0,1-2.5-.375q-8-2.25-8-9,0-8.748,11-20.25t19.75-11.5a6.931,6.931,0,0,1,4.688,1.563,4.856,4.856,0,0,1,1.812,3.812q0,7-10.562,13.375t-17.188,6.375a5.5,5.5,0,0,1-1.75-.25,14.457,14.457,0,0,0-1.875,7.125q0,6.5,6.188,6.5t16.062-8.375a79.867,79.867,0,0,0,13-13q1.125-1.5,1.938-1.5t.812.75a4.216,4.216,0,0,1-1,2.125,76.808,76.808,0,0,1-16.25,15.75Q549.467,108.817,542.843,108.816Zm23.625-36q0-1.5-3.187-1.5t-9.063,4.875a55.8,55.8,0,0,0-12.25,13.625,5.069,5.069,0,0,0,1.625.25q4.749,0,12.688-5.062t9.937-11.063A3.079,3.079,0,0,0,566.468,72.816Z"
              />
            </clipPath>
          </defs>
          <g id="T">
            <g className="tbanim-2">
              <polyline
                className="tbanim-3 stroke-dash t1"
                points="129.657 10.562 133.657 16.562 133.657 20.894 129.657 24.562 121.657 26.562 88.657 14.562 63.657 5.562 32.657 7.562 9.355 26.562 5.657 42.562 7.657 50.562 13.657 56.562 22.657 58.562 37.657 59.562"
              />
              <polyline
                className="tbanim-4  stroke-dash t2"
                points="18.657 64.562 18.657 73.928 26.253 73.928 35.657 70.562 46.657 61.562 53.657 51.562 56.657 43.562 55.657 37.562 50.657 34.562 43.657 35.562 34.657 37.562 11.657 61.562 5.657 83.653 7.657 97.562 14.657 102.562 21.657 103.562 33.657 99.562 50.657 84.562 87.657 33.562 96.657 29.562 108.657 31.562"
              />
            </g>
          </g>
          <g id="y">
            <g className="tbanim-5  stroke-dash y">
              <polyline
                className="tbanim-4"
                points="107.803 55.316 83.657 84.562 83.657 89.815 86.657 91.562 91.132 91.562 105.63 81.77 130.657 60.562 102.657 108.562 93.657 116.562 86.657 118.562 81.657 115.562 78.657 112.562 75.042 104.457"
              />
            </g>
          </g>
          <g id="l">
            <g className="tbanim-6  stroke-dash l">
              <polyline
                className="tbanim-3"
                points="169.657 29.562 149.007 51.892 135.086 77.129 131.657 85.562 134.383 92.205 139.657 93.562 147.657 90.562 154.657 84.562 160.657 78.562 166.007 72.752"
              />
            </g>
          </g>
          <g id="e">
            <g className="tbanim-7  stroke-dash e1">
              <polyline
                className="tbanim-8"
                points="157.34 75.316 166.007 78.556 172.657 77.562 183.657 71.562 190.507 64.528 192.005 59.456 189.657 56.562 185.396 56.562 181.037 58.379 164.657 72.562 159.657 84.562 160.657 91.562 165.503 94.504 169.704 94.504 174.673 92.663 180.657 89.562 185.657 85.562 190.657 80.562 192.657 78.562"
              />
            </g>
          </g>
          <g id="r">
            <g className="tbanim-9  stroke-dash r1">
              <polyline
                className="tbanim-4"
                points="190.657 80.562 201.657 68.562 210.657 56.562 217.657 47.562 216.657 45.562 213.657 47.562 211.657 50.562 212.657 57.562 213.657 60.562 217.657 60.803 227.657 57.562 213.657 74.562 211.657 81.562 211.657 89.562 214.657 92.562 219.657 93.562 226.657 91.562 233.657 86.562 239.657 81.562 248.657 71.562"
              />
            </g>
          </g>
          <g id="B">
            <g className="tbanim-10  stroke-dash-2 b">
              <polyline
                className="tbanim-3"
                points="292.663 55.316 284.657 49.562 283.657 41.562 288.16 32.703 298.657 22.562 309.657 17.562 320.613 13.957 331.657 12.562 344.657 11.562 353.657 12.562 363.657 15.562 371.657 20.562 374.657 34.562 368.657 44.562 361.657 48.562 354.657 50.562 341.657 51.562 332.657 51.562 345.664 54.754 357.657 62.562 357.657 77.562 348.657 88.562 335.95 95.629 325.657 98.562 314.477 98.562 302.378 94.504 293.657 81.562 285.657 63.562 282.254 64.402 276.657 70.562 271.657 81.562 270.657 90.562 273.129 94.879 275.621 95.629 279.696 94.879 292.657 84.562 309.657 66.562 327.657 42.562 347.657 24.562 356.657 23.562"
              />
            </g>
          </g>
          <g id="u">
            <g className="tbanim-11  stroke-dash u">
              <polyline
                className="tbanim-12"
                points="365.657 82.562 389.657 58.562 375.657 78.562 371.657 87.562 372.657 93.562 375.657 93.562 379.657 92.562 386.657 88.562 398.044 76.062 416.657 56.562 399.657 81.562 398.657 89.562 400.657 93.562 403.922 93.562 409.657 90.562 424.657 76.562"
              />
            </g>
          </g>
          <g id="r-2" data-name="r">
            <g className="tbanim-13">
              <polyline
                className="tbanim-8  stroke-dash r2"
                points="420.488 80.453 432.657 66.562 440.657 55.562 446.657 48.562 446.657 45.494 444.657 46.562 442.657 48.562 441.657 51.562 441.657 57.562 443.657 60.562 447.657 61.562 455.657 57.562 445.657 70.562 441.657 79.562 440.657 84.562 440.657 89.562 444.657 93.562 449.001 93.562 453.657 91.562 459.657 89.562 464.657 85.562 470.657 80.562 477.657 72.562"
              />
            </g>
          </g>
          <g id="k">
            <g className="tbanim-14">
              <polyline
                className="tbanim-4  stroke-dash k"
                points="467.875 94.879 484.657 62.562 507.657 34.562 486.657 61.562 493.657 59.562 502.864 54.754 510.657 52.562 515.919 54.504 517.657 60.562 516.5 65.611 511.612 69.254 503.657 72.562 497 74.089 491.657 74.562 482.657 74.562 487.657 87.562 493.657 93.562 499.375 94.008 504.657 92.562 509.657 88.562 515 84.856 522.648 77.129 526.657 71.562"
              />
            </g>
          </g>
          <g id="e-2" data-name="e">
            <g className="tbanim-15  stroke-dash e2">
              <polyline
                id="_Path_"
                data-name="&lt;Path&gt;"
                className="tbanim-16"
                points="518.657 74.562 526.125 78.262 533.415 77.129 541.624 73.254 549.657 65.562 552.657 60.562 550.625 57.132 545.657 56.562 541.657 57.562 535.657 61.977 530.657 65.562 526.657 70.562 519.657 83.562 519.657 88.562 522.657 92.562 526.96 94.879 534.657 92.562 542.657 87.562 550.625 81.034 557.657 73.562 561.657 68.562"
              />
            </g>
          </g>
        </svg>
      )}
    </DivContainer>
  );
};

const DivContainer = styled.div<{
  width: number;
  height: number;
  color: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  polyline {
    stroke: ${(props) => props.color} !important;
  }

  .tbanim-1,
  .tbanim-12,
  .tbanim-16,
  .tbanim-3,
  .tbanim-4,
  .tbanim-8 {
    fill: none;
  }

  .tbanim-2 {
    clip-path: url(#tbanim-cp);
  }

  .tbanim-12,
  .tbanim-16,
  .tbanim-3,
  .tbanim-4,
  .tbanim-8 {
    stroke: #000;
    stroke-miterlimit: 10;
  }

  .tbanim-3 {
    stroke-width: 11px;
  }

  .tbanim-4 {
    stroke-width: 9px;
  }

  .tbanim-5 {
    clip-path: url(#tbanim-cp-2);
  }

  .tbanim-6 {
    clip-path: url(#tbanim-cp-3);
  }

  .tbanim-7 {
    clip-path: url(#tbanim-cp-4);
  }

  .tbanim-8 {
    stroke-width: 8px;
  }

  .tbanim-9 {
    clip-path: url(#tbanim-cp-5);
  }

  .tbanim-10 {
    clip-path: url(#tbanim-cp-6);
  }

  .tbanim-11 {
    clip-path: url(#tbanim-cp-7);
  }

  .tbanim-12 {
    stroke-width: 10px;
  }

  .tbanim-13 {
    clip-path: url(#tbanim-cp-8);
  }

  .tbanim-14 {
    clip-path: url(#tbanim-cp-9);
  }

  .tbanim-15 {
    clip-path: url(#tbanim-cp-10);
  }

  .tbanim-16 {
    stroke-width: 7px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .stroke-dash {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
  }

  .stroke-dash-2 {
    stroke-dasharray: 600;
    stroke-dashoffset: 600;
  }

  .t1 {
    animation: dash 0.35s linear forwards;
  }

  .t2 {
    animation: dash 0.35s linear forwards;
  }

  .y {
    animation: dash 0.35s linear forwards 0.1s;
  }

  .l {
    animation: dash 0.35s linear forwards 0.2s;
  }

  .e1 {
    animation: dash 0.35s linear forwards 0.4s;
  }

  .r1 {
    animation: dash 0.35s linear forwards 0.6s;
  }

  .b {
    animation: dash 0.7s linear forwards 0.8s;
  }

  .u {
    animation: dash 0.35s linear forwards 1s;
  }

  .r2 {
    animation: dash 0.35s linear forwards 1.2s;
  }

  .k {
    animation: dash 0.35s linear forwards 1.4s;
  }

  .e2 {
    animation: dash 0.35s linear forwards 1.6s;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export default TylerBurkeAnimatedSvg;
