import styled from "styled-components/macro";
import Fonts from "../fonts";

const SpanPlayfairFont = styled.span<{
  size: string;
  color: string;
}>`
  font-family: ${Fonts.playfair};
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
`;

export default SpanPlayfairFont;
