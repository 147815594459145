import React from "react";
import SpanRobotoFont from "./SpanRobotoFont";
import Palette from "../palette";
import styled from "styled-components/macro";
import DivFlex from "./DivFlex";
import DivMargin from "./DivMargin";

interface Props {
  children: string;
}

const PageDivide: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <DivFlex justify={"center"} align={"center"}>
      <DivRule />
      <DivMargin right={30} left={30}>
        <SpanRobotoFont color={Palette.white} size={"25px"} weight={"regular"}>
          {props.children}
        </SpanRobotoFont>
      </DivMargin>
      <DivRule />
    </DivFlex>
  );
};

const DivRule = styled.div`
  height: 1px;
  background-color: ${Palette.secondary};
  width: 100%;
`;

export default PageDivide;
