import React from "react";
import { motion } from "framer-motion";
import { CustomTransition } from "../models/CustomTransition";

interface Props {
  visible: boolean;
  transition?: CustomTransition;
  className?: string;
}

const MotionSlideConnectorDown: React.FC<Props> = (
  props: Props
): JSX.Element => {
  const slideConnectorDown = {
    hidden: {
      translateY: "-101%",
    },
    visible: {
      translateY: "0%",
      transition: {
        type: "tween",
        delay: props.transition ? props.transition.delay : undefined,
        duration: props.transition ? props.transition.duration : undefined,
      },
    },
  };

  return (
    <motion.div
      className={props.className}
      variants={slideConnectorDown}
      initial={"hidden"}
      animate={props.visible ? "visible" : "hidden"}
    />
  );
};

export default MotionSlideConnectorDown;
