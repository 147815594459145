import React, { useContext } from "react";
import { Portal } from "@material-ui/core";
import { GlobalContext } from "./GlobalContextWrapper";

interface Props {}

const LoadingIndicator: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx = useContext(GlobalContext);

  return (
    <Portal>
      {globalCtx.isLoading && (
        <div id="loading-bar" className="loading-bar-container">
          <div className="loading-bar" />
        </div>
      )}
    </Portal>
  );
};

export default LoadingIndicator;
