import styled from "styled-components/macro";
import Fonts from "../fonts";
import { calcWeight } from "../functions/calcWeight.fn";
import Palette from "../palette";

const Anchor = styled.a<{
  color?: string;
  hoverColor?: string;
  size?: string;
  underline?: boolean;
}>`
  color: ${(props) => (props.color ? props.color : Palette.secondaryLight)};
  font-family: ${Fonts.roboto};
  font-weight: ${calcWeight("regular")};
  font-size: 20px;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  transition: all 50ms ease-in;
  -webkit-font-smoothing: antialiased;

  @media (max-width: 575px) {
    font-size: 15px;
  }

  &:hover {
    transition: all 100ms ease-out;
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : Palette.secondaryDark};
  }
`;

export default Anchor;
