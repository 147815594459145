import { RefObject } from "react";
import { scrollIntoView } from "./scrollTo";
import * as H from "history";

export function goToAnchor(
  history: H.History,
  path: string,
  ref: RefObject<HTMLDivElement>
) {
  if (history.location.pathname !== path) {
    history.push({ pathname: path, state: { ref } });
  } else {
    scrollIntoView(ref);
  }
}
