import React, { useContext } from "react";
import Navbar from "../components/Navbar";
import { Col, Container, Row } from "react-grid-system";
import Footer from "../components/Footer";
import { BreakpointsModel, useBreakpoints } from "../hooks/useBreakpoints";
import Breadcrumbs from "../components/Breadcrumbs";
import breadcrumbFromHome from "../functions/crumbsFromHome.fn";
import crumb from "../functions/crumb.fn";
import WorkItemContextWrapper from "../components/WorkItemContextWrapper";
import styled from "styled-components/macro";
import Anchor from "../components/Anchor";
import SpanRobotoFont from "../components/SpanRobotoFont";
import DivFlex from "../components/DivFlex";
import DivMargin from "../components/DivMargin";
import {
  WorkItemInfoCardModel,
  WorkItemModel,
  WorkItemThemeModel,
} from "../models/workItem.model";
import WorkItemInfoCard from "../components/WorkItemInfoCard";
import BrowserMockup from "../components/BrowserMockup";
import DivBackgroundColor from "../components/DivBackgroundColor";
import { isEven } from "../functions/isEven.fn";
import MobileShowcase from "../components/MobileShowcase";
import Button from "../components/Button";
import { useHistory } from "react-router-dom";
import { IoIosUndo } from "react-icons/io";
import DivPadding from "../components/DivPadding";
import MenuModal from "../components/MenuModal";
import Palette from "../palette";
import MenuIcon from "../components/MenuIcon";
import { GlobalContext } from "../components/GlobalContextWrapper";
import FadeIn from "../components/FadeIn";
import SlideIn from "../components/SlideIn";

interface Props {
  workItem: WorkItemModel;
  children?: any;
}

const WorkItemLayout: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx = useContext(GlobalContext);
  const breakpoints = useBreakpoints();
  const history = useHistory();

  const renderInfoCards = (cards: WorkItemInfoCardModel[]) => (
    <Col xs={12} xxl={5.5}>
      {cards.map((card, cardIndex) => (
        <Row key={cardIndex}>
          <Col>
            <DivMargin bottom={cardIndex !== cards.length - 1 ? 20 : 0}>
              <FadeIn up distance={20}>
                <SpanRobotoFont
                  size={breakpoints.isSm ? "20px" : "15px"}
                  color={props.workItem.theme.text}
                >
                  <WorkItemInfoCard card={card} theme={props.workItem.theme} />
                </SpanRobotoFont>
              </FadeIn>
            </DivMargin>
          </Col>
        </Row>
      ))}
    </Col>
  );

  const renderBrowserMockup = (screenImgSrc: string, blockIndex: number) => (
    <Col xs={12} xxl={6.5}>
      <DivFlex justify={"center"} align={"center"}>
        <DivMargin
          top={!breakpoints.isXxl ? 50 : 0}
          bottom={!breakpoints.isXxl ? 50 : 0}
        >
          <SlideIn
            left={!isEven(blockIndex)}
            distance={breakpoints.isXxl ? 20 : 0}
          >
            <BrowserMockup
              frameColor={props.workItem.theme.deviceTrim}
              screenSrc={screenImgSrc}
              animateDir={isEven(blockIndex) ? "right" : "left"}
            />
          </SlideIn>
        </DivMargin>
      </DivFlex>
    </Col>
  );

  const renderShowcase = (showcaseIndex: number) => {
    if (props.workItem.showcases.length > showcaseIndex) {
      return (
        <Row>
          <Col>
            <DivMargin
              top={!breakpoints.isMd ? 35 : 250}
              bottom={!breakpoints.isMd ? 35 : 175}
            >
              <MobileShowcase
                showcase={props.workItem.showcases[showcaseIndex]}
              />
            </DivMargin>
          </Col>
        </Row>
      );
    }
  };

  const renderInfoBlock = (blockIndex: number) => {
    if (props.workItem.infoBlocks.length > blockIndex) {
      return (
        <Row>
          <Col>
            <DivSection breakpoints={breakpoints}>
              <Row align={"center"}>
                {isEven(blockIndex) ? (
                  <>
                    {renderInfoCards(
                      props.workItem.infoBlocks[blockIndex].cards
                    )}
                    {renderBrowserMockup(
                      props.workItem.infoBlocks[blockIndex].imgSrc,
                      blockIndex
                    )}
                  </>
                ) : (
                  <>
                    {renderBrowserMockup(
                      props.workItem.infoBlocks[blockIndex].imgSrc,
                      blockIndex
                    )}
                    {renderInfoCards(
                      props.workItem.infoBlocks[blockIndex].cards
                    )}
                  </>
                )}
              </Row>
            </DivSection>
          </Col>
        </Row>
      );
    }
  };

  return (
    <WorkItemContextWrapper workItem={props.workItem}>
      {globalCtx.isMenuOpen && (
        <MenuModal highlightColor={Palette.secondaryLight} />
      )}
      <MenuIcon
        visible={!globalCtx.isMenuOpen}
        color={props.workItem.theme.text}
        hoverColor={Palette.secondaryLight}
      />
      {breakpoints.isXl && (
        <Navbar
          bgColor={props.workItem.theme.navbar}
          highlightColor={props.workItem.theme.highlight}
          hideButtons
          noFloat
        />
      )}
      <DivBackgroundColor bgColor={props.workItem.theme.bg}>
        <DivContainer fluid>
          <Row justify={"center"}>
            <Col xs={12} lg={10}>
              <DivBackgroundColor bgColor={props.workItem.theme.containerBg}>
                <DivPadding top={75} bottom={150}>
                  <Row>
                    <Col>
                      <DivSection breakpoints={breakpoints}>
                        <Breadcrumbs
                          color={props.workItem.theme.link}
                          hoverColor={props.workItem.theme.linkHover}
                          deadColor={props.workItem.theme.text}
                          breadcrumbs={breadcrumbFromHome(
                            crumb("Work", "/#/work")
                          )}
                          currentPage={props.workItem.name}
                        />
                      </DivSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DivImgSplash
                        bgSrc={props.workItem.splashSrc}
                        bgPos={props.workItem.splashPosition}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DivSection breakpoints={breakpoints}>
                        <Row align={"center"}>
                          <Col xs={12} md={2.5} lg={2} xl={1.5} xxl={1}>
                            <DivFlex
                              justify={"flex-start"}
                              align={breakpoints.isLg ? "center" : "flex-start"}
                            >
                              <DivLogoHighlight
                                itemtheme={props.workItem.theme}
                              />
                              <ImgLogo
                                src={props.workItem.logoSrc}
                                alt={props.workItem.logoTitle}
                                title={props.workItem.logoTitle}
                              />
                            </DivFlex>
                          </Col>
                          <Col xs={12} md={9.5} lg={9} xl={9.5} xxl={10}>
                            <DivFlex justify={"flex-start"} align={"flex-end"}>
                              <DivMargin
                                top={breakpoints.isLg ? 0 : 20}
                                right={25}
                              >
                                <SpanRobotoFont
                                  size={breakpoints.isXl ? "55px" : "35px"}
                                  color={props.workItem.theme.text}
                                  weight={"bold"}
                                  flat
                                >
                                  {props.workItem.name}
                                </SpanRobotoFont>
                              </DivMargin>
                              {props.workItem.webLink && breakpoints.isLg && (
                                <DivMargin bottom={10}>
                                  <Anchor
                                    href={props.workItem.webLink}
                                    color={props.workItem.theme.link}
                                    hoverColor={props.workItem.theme.linkHover}
                                    target={"_new"}
                                  >
                                    {props.workItem.webLink}
                                  </Anchor>
                                </DivMargin>
                              )}
                            </DivFlex>
                          </Col>
                          <Col xs={12} lg={1}>
                            {props.workItem.webLink && !breakpoints.isLg && (
                              <DivMargin
                                top={breakpoints.isLg ? 0 : 20}
                                bottom={breakpoints.isLg ? 10 : 20}
                              >
                                <Anchor
                                  href={props.workItem.webLink}
                                  color={props.workItem.theme.link}
                                  hoverColor={props.workItem.theme.linkHover}
                                  target={"_new"}
                                >
                                  {props.workItem.webLink}
                                </Anchor>
                              </DivMargin>
                            )}
                            <DivFlex
                              justify={
                                breakpoints.isLg ? "flex-end" : "flex-start"
                              }
                            >
                              <SpanRobotoFont
                                size={"35px"}
                                color={props.workItem.theme.primary}
                                weight={"bold"}
                                flat
                              >
                                {props.workItem.year}
                              </SpanRobotoFont>
                            </DivFlex>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <DivMargin top={25}>
                              <SpanRobotoFont
                                size={"15px"}
                                weight={"bold"}
                                color={props.workItem.theme.text}
                              >
                                Designed:&nbsp;
                                <SpanRobotoFont
                                  size={"15px"}
                                  weight={"bold"}
                                  color={props.workItem.theme.highlight}
                                >
                                  {props.workItem.designedByMe
                                    ? "By me"
                                    : "By the client"}
                                </SpanRobotoFont>
                              </SpanRobotoFont>
                            </DivMargin>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <DivMargin top={25} bottom={50}>
                              <SpanRobotoFont
                                size={breakpoints.isSm ? "20px" : "15px"}
                                color={props.workItem.theme.text}
                              >
                                {props.workItem.description}
                              </SpanRobotoFont>
                            </DivMargin>
                          </Col>
                        </Row>
                      </DivSection>
                    </Col>
                  </Row>
                  {renderInfoBlock(0)}
                  {renderShowcase(0)}
                  {renderInfoBlock(1)}
                  <Row>
                    <Col>
                      <DivMargin top={115}>
                        <DivFlex justify={"center"} align={"center"}>
                          <ButtonBack
                            icon={IoIosUndo}
                            itemtheme={props.workItem.theme}
                            onClick={() => history.push("/work")}
                          >
                            Back To Work
                          </ButtonBack>
                        </DivFlex>
                      </DivMargin>
                    </Col>
                  </Row>
                </DivPadding>
              </DivBackgroundColor>
            </Col>
          </Row>
        </DivContainer>
        <Container fluid>
          <Row>
            <Col>
              <Footer
                bgColor={props.workItem.theme.footer}
                highlightColor={props.workItem.theme.highlight}
                highlightHoverColor={props.workItem.theme.highlightHover}
              />
            </Col>
          </Row>
        </Container>
      </DivBackgroundColor>
    </WorkItemContextWrapper>
  );
};

const DivContainer = styled(Container)`
  margin-top: 0;
`;

const DivSection = styled.div<{ breakpoints: BreakpointsModel }>`
  padding: 0 ${(props) => (props.breakpoints.isMd ? 40 : 10)}px;
`;

const DivImgSplash = styled.div<{ bgSrc: string; bgPos: string }>`
  height: 350px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
  background-image: url("${(props) => props.bgSrc}");
  background-repeat: no-repeat;
  background-position: ${(props) => props.bgPos};
  background-size: cover;
`;

const DivLogoHighlight = styled.div<{ itemtheme: WorkItemThemeModel }>`
  margin-right: 15px;
  height: 70px;
  width: 5px;
  background-color: ${(props) => props.itemtheme.primary};
`;

const ImgLogo = styled.img`
  height: 75px;
  width: auto;
  border-radius: 15px;
`;

const ButtonBack = styled(Button)<{ itemtheme: WorkItemThemeModel }>`
  ::before {
    background-color: ${(props) => props.itemtheme.button};
    border-radius: 0;
  }

  &:hover {
    ::before {
      background-color: ${(props) => props.itemtheme.buttonHover};
      transform: none;
      box-shadow: none;
    }
  }
`;

export default WorkItemLayout;
