import React, { useEffect } from "react";
import styled from "styled-components/macro";
import Palette from "../../palette";
import FrontendServices from "../../components/FrontendServices";
import BackendServices from "../../components/BackendServices";
import HeadingOverlined from "../../components/HeadingOverlined";
import { motion, useAnimation } from "framer-motion";
import Waypoint from "../../components/Waypoint";
import { contentBasePath } from "../../constants";
import { BreakpointsModel, useBreakpoints } from "../../hooks/useBreakpoints";
import DivMargin from "../../components/DivMargin";

interface Props {}

const ServicesSection: React.FC<Props> = (props: Props): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const controlsIceberg = useAnimation();
  const controlsCloudOne = useAnimation();
  const controlsCloudTwo = useAnimation();
  const controlsSun = useAnimation();
  const breakpoints = useBreakpoints();

  const calcMiddleIcebergWidth = (): number => {
    if (breakpoints.isXxl) {
      return 58;
    } else if (breakpoints.isXl) {
      return 49;
    } else {
      return 29;
    }
  };

  const startAnimation = () => {
    controlsIceberg.start({
      backgroundPositionY: "15px",
      transition: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 3,
      },
    });
    controlsCloudOne.start({
      translateX: "-200px",
      transition: {
        type: "tween",
        ease: "linear",
        repeat: Infinity,
        repeatType: "reverse",
        duration: 40,
      },
    });
    controlsCloudTwo.start({
      translateX: "100px",
      transition: {
        type: "tween",
        ease: "linear",
        repeat: Infinity,
        repeatType: "reverse",
        duration: 40,
      },
    });
    controlsSun.start({
      scale: 1.05,
      transition: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 2,
      },
    });
  };

  const stopAnimation = () => {
    controlsIceberg.stop();
    controlsCloudOne.stop();
    controlsCloudTwo.stop();
    controlsSun.stop();
  };

  const resetAnimation = () => {
    controlsIceberg.set({ backgroundPositionY: "0px" });
    controlsCloudOne.set({ translateX: "0px" });
    controlsCloudTwo.set({ translateX: "0px" });
    controlsSun.set({ scale: 1 });
  };

  useEffect(() => {
    if (visible) {
      startAnimation();
    } else {
      stopAnimation();
      resetAnimation();
    }
  });

  return (
    <Waypoint onChange={setVisible}>
      <DivMargin left={!breakpoints.isSm ? 20 : 0}>
        <HeadingOverlined fontSize={!breakpoints.isSm ? 45 : 65}>
          What I do
        </HeadingOverlined>
      </DivMargin>
      <DivIceberg
        initial={{ backgroundPositionY: "0px" }}
        animate={controlsIceberg}
        middleIcebergWidth={calcMiddleIcebergWidth()}
        breakpoints={breakpoints}
      >
        {breakpoints.isLg && (
          <>
            <DivCloudOne
              initial={{ translateX: "0px" }}
              animate={controlsCloudOne}
            />
            <DivCloudTwo
              initial={{ translateX: "0px" }}
              animate={controlsCloudTwo}
            />
            <ImgSun initial={{ scale: 1 }} animate={controlsSun} />
          </>
        )}
        <FrontendServices />
        <BackendServices />
      </DivIceberg>
    </Waypoint>
  );
};

const ImgSun = styled(motion.div)`
  width: 190px;
  height: 190px;
  background-image: url("${`${contentBasePath}/images/sun.png`}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: 30%;
  top: 50px;
`;

const DivCloud = styled(motion.div)`
  width: 450px;
  height: 100px;
  background-image: url("${`${contentBasePath}/images/cloud.png`}");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
`;

const DivCloudOne = styled(DivCloud)`
  top: 100px;
`;

const DivCloudTwo = styled(DivCloud)`
  right: 0;
  top: 175px;
`;

const DivIceberg = styled(motion.div)<{
  middleIcebergWidth: number;
  breakpoints: BreakpointsModel;
}>`
  background-image: ${(props) =>
    props.breakpoints.isSm
      ? `url("${contentBasePath}/images/iceberg.png")`
      : undefined};
  background-repeat: no-repeat;
  background-position: ${(props) =>
    props.breakpoints.isLg ? "right" : "center"};
  background-size: contain;
  padding-bottom: 35px;

  .service-card {
    margin-top: 50px;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .bg-tertiary {
    background-color: ${Palette.tertiary};
  }

  .h-100p {
    height: 100%;
  }

  .h-50p {
    height: 50%;
  }

  .h-3 {
    height: 10px;
  }

  .w-3 {
    width: 10px;
  }

  .w-20p {
    width: 20%;
  }

  .w-middle-iceberg {
    width: ${(props) => props.middleIcebergWidth}%;
  }

  .w-100p {
    width: 100%;
  }

  .flex {
    display: flex;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .align-start {
    align-items: flex-start;
  }

  .align-end {
    align-items: flex-end;
  }

  .align-center {
    align-items: center;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .connect-cross-top {
    position: absolute;
    left: 20%;
    top: 0;
    margin-left: -10px;
    background-color: ${Palette.tertiary};
    height: 50%;
    width: 10px;
  }

  .connect-cross-bottom {
    position: absolute;
    left: 20%;
    bottom: -25px;
    margin-left: -10px;
    background-color: ${Palette.tertiary};
    height: calc(50% + 25px);
    width: 10px;
  }
`;

export default ServicesSection;
