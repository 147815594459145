import React from "react";
import styled from "styled-components/macro";
import Palette from "../palette";
import { motion } from "framer-motion";
import { myEmail } from "../constants";

interface Props {
  fontSize: number;
  highlightThickness: number;
}

const EmailLink: React.FC<Props> = (props: Props): JSX.Element => {
  const [hover, setHover] = React.useState(false);

  const hoverHighlight = {
    normal: {
      backgroundColor: Palette.tertiary,
      boxShadow: "none",
      transition: {
        type: "tween",
        ease: "linear",
        duration: 0.25,
      },
    },
    hover: {
      backgroundColor: Palette.secondary,
      boxShadow: `0 0 6px 1px ${Palette.secondary}`,
      transition: {
        type: "tween",
        ease: "linear",
        duration: 0.075,
      },
    },
  };

  return (
    <DivContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div>
        <AnchorEmail href={`mailto:${myEmail}`} fontSize={props.fontSize}>
          {myEmail}
        </AnchorEmail>
      </div>
      <DivUnderline
        thickness={props.highlightThickness}
        variants={hoverHighlight}
        initial={"normal"}
        animate={hover ? "hover" : "normal"}
      />
    </DivContainer>
  );
};

const DivContainer = styled.div`
  display: inline-block;
`;

const AnchorEmail = styled.a<{ fontSize: number }>`
  color: ${Palette.white};
  font-family: "Roboto", sans-serif;
  font-size: ${(props) => props.fontSize}px;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
`;

const DivUnderline = styled(motion.div)<{ thickness: number }>`
  width: 100%;
  height: ${(props) => props.thickness}px;
`;

export default EmailLink;
