import React from "react";
import WorkItemLayout from "../layouts/WorkItemLayout";
import { themeNadohe } from "../themes";
import { contentBasePath } from "../constants";
import { useBreakpoints } from "../hooks/useBreakpoints";
import Anchor from "../components/Anchor";

interface Props {}

const WorkNadohePage: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  return (
    <WorkItemLayout
      workItem={{
        theme: themeNadohe,
        logoSrc: `${contentBasePath}/images/nadohe-logo.jpg`,
        logoTitle: "nadohe.org",
        name: "NADOHE",
        year: "2022",
        webLink: "https://nadohe.org",
        description: (
          <>
            The National Association of Diversity Officers in Higher Education
            (NADOHE) is the preeminent voice for chief diversity officers. It
            leads the national and international conversation on diversity,
            equity, and inclusion in post-secondary education. Ultimately, it
            investigates, influences, and innovates to transform higher
            education so inclusive excellence thrives at the core of each
            institution worldwide. This project was a re-build and re-brand of
            the NADOHE website - including a migration onto AWS CloudFront and
            delivery of a highly customisable headless CMS to empower the
            organisation to create and update their site content without the
            need for development.
          </>
        ),
        splashSrc: `${contentBasePath}/images/nadohe-splash.jpg`,
        splashPosition: "top center",
        showcases: [
          {
            bgSrc: `${contentBasePath}/images/nadohe-mobile-splash.jpg`,
            phoneOne: {
              id: "screen-1",
              src: `${contentBasePath}/images/nadohe-mobile-1.png`,
              title: "Screenshot of NADOHE website",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeNadohe.deviceTrim,
            },
            phoneTwo: {
              id: "screen-2",
              src: `${contentBasePath}/images/nadohe-mobile-2.png`,
              title: "Screenshot of NADOHE website",
              animate: breakpoints.isMd,
              rotateDirection: "left",
              trimColor: themeNadohe.deviceTrim,
            },
            phoneThree: {
              id: "screen-3",
              src: `${contentBasePath}/images/nadohe-mobile-3.png`,
              title: "Screenshot of NADOHE website",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeNadohe.deviceTrim,
            },
          },
        ],
        infoBlocks: [
          {
            cards: [
              {
                heading: "Content Management",
                body: (
                  <>
                    Almost every aspect of the NADOHE site can be modified using
                    the bundled&nbsp;
                    <Anchor
                      color={themeNadohe.link}
                      hoverColor={themeNadohe.linkHover}
                      href={"https://www.netlifycms.org/"}
                    >
                      Netlify CMS
                    </Anchor>
                    &nbsp;administration site. Content creators have 10
                    pre-defined templates to choose from when creating new
                    webpages, and each page is enabled with Markdown rich text
                    editing which is parsed into HTML at build time.
                  </>
                ),
              },
              {
                heading: "Continuous Deployment",
                body: (
                  <>
                    An efficiently optimized continuous deployment pipeline
                    using&nbsp;
                    <Anchor
                      color={themeNadohe.link}
                      hoverColor={themeNadohe.linkHover}
                      href={"https://github.com/features/actions"}
                    >
                      Github Actions
                    </Anchor>
                    &nbsp;enables the building and deployment of each change
                    made in the CMS to production within 3 minutes.
                  </>
                ),
              },
              {
                heading: "Performance",
                body: (
                  <>
                    Web performance is incredibly important for modern websites.
                    Not only does it determine how quickly users can view your
                    pages, it also plays a huge role in your search engine
                    rankings. Scoring a perfect 100/100 on the&nbsp;
                    <Anchor
                      color={themeNadohe.link}
                      hoverColor={themeNadohe.linkHover}
                      href={
                        "https://chrome.google.com/webstore/detail/lighthouse/blipmdconlkpinefehnmjammfjpmpbjk?hl=en"
                      }
                    >
                      Google Lighthouse
                    </Anchor>
                    &nbsp;performance metric, the re-booted NADOHE site has come
                    leaps and bounds from the legacy site's score of 54.
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/nadohe-browser-2.jpg`,
            imgTitle: "Screenshot of NADOHE website",
          },
          {
            cards: [
              {
                heading: "Image Processing",
                body: (
                  <>
                    Leveraging the image processing power of&nbsp;
                    <Anchor
                      color={themeNadohe.link}
                      hoverColor={themeNadohe.linkHover}
                      href={"https://www.gatsbyjs.com/"}
                    >
                      GatsbyJS
                    </Anchor>
                    , images uploaded or referenced via the CMS are
                    automatically processed to various sizes, shapes and
                    placeholder versions to best optimize page load performance
                    and reduce cumulative layout shift.
                  </>
                ),
              },
              {
                heading: "Accessibility",
                body: (
                  <>
                    Accessibility and diversity go together like peanut butter
                    and jelly. It was paramount for the NADOHE site to be
                    accessible to those with seeing and hearing disabilities.
                    With a score of 98/100 on the&nbsp;
                    <Anchor
                      color={themeNadohe.link}
                      hoverColor={themeNadohe.linkHover}
                      href={
                        "https://chrome.google.com/webstore/detail/lighthouse/blipmdconlkpinefehnmjammfjpmpbjk?hl=en"
                      }
                    >
                      Google Lighthouse
                    </Anchor>
                    &nbsp;accessibility metric, the re-booted NADOHE site is
                    WCAG 2 level AA compliant.
                  </>
                ),
              },
              {
                heading: "SEO and Indexing",
                body: (
                  <>
                    Page titles, keywords and page slugs can all be customised
                    for each page using the CMS and new content is automatically
                    indexed using&nbsp;
                    <Anchor
                      color={themeNadohe.link}
                      hoverColor={themeNadohe.linkHover}
                      href={
                        "https://www.algolia.com/products/search-and-discovery/hosted-search-api/"
                      }
                    >
                      Algolia
                    </Anchor>
                    &nbsp;for a richly featured site search experience. A new
                    sitemap is generated every time a page is created or
                    changed.
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/nadohe-browser-3.jpg`,
            imgTitle: "Screenshot of NADOHE website",
          },
        ],
      }}
    />
  );
};

export default WorkNadohePage;
