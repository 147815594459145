import { WorkItemThemeModel } from "./models/workItem.model";

export const themeEso: WorkItemThemeModel = {
  navbar: "#001B2E",
  highlight: "#fb8c60",
  highlightHover: "#cf7451",
  footer: "#001B2E",
  deviceTrim: "#082A47",
  link: "#f7b500",
  linkHover: "#9b7000",
  primary: "#fb8c60",
  primaryDark: "#cf7451",
  secondary: "#6A2121",
  secondaryDark: "#561b1b",
  bg: "#2B2B2B",
  containerBg: "rgba(0,0,0,0.85)",
  cardBg: "rgba(26,26,26,0.55)",
  cardTrim: "#3e3e3e",
  cardHighlight: "#6A2121",
  heading: "#fb8c60",
  text: "white",
  button: "#6A2121",
  buttonHover: "#561b1b",
};

export const themeMlc: WorkItemThemeModel = {
  navbar: "#C9510A",
  highlight: "#C9510A",
  highlightHover: "#b54809",
  footer: "#171919",
  deviceTrim: "#3B3B3B",
  link: "#1C838C",
  linkHover: "#176c73",
  primary: "#C9510A",
  primaryDark: "#b54809",
  secondary: "#1C838C",
  secondaryDark: "#176c73",
  bg: "white",
  containerBg: "transparent",
  cardBg: "transparent",
  cardTrim: "#c8c6c2",
  cardHighlight: "#1C838C",
  heading: "#3B3B3B",
  text: "#3B3B3B",
  button: "#C9510A",
  buttonHover: "#b54809",
};

export const themeKebc: WorkItemThemeModel = {
  navbar: "#1D1D1D",
  highlight: "#0091E8",
  highlightHover: "#017bc1",
  footer: "#1D1D1D",
  deviceTrim: "#0C2E53",
  link: "#FF0000",
  linkHover: "#c30000",
  primary: "#FF0000",
  primaryDark: "#c30000",
  secondary: "#0091E8",
  secondaryDark: "#017bc1",
  bg: "#012947",
  containerBg: "transparent",
  cardBg: "#011A28",
  cardTrim: "transparent",
  cardHighlight: "#FF0000",
  heading: "#0091E8",
  text: "white",
  button: "#FF0000",
  buttonHover: "#c30000",
};

export const themeTalkSig: WorkItemThemeModel = {
  navbar: "#000000",
  highlight: "#D5AB29",
  highlightHover: "#ab8821",
  footer: "#000000",
  deviceTrim: "#565656",
  link: "#D5AB29",
  linkHover: "#ab8821",
  primary: "#D5AB29",
  primaryDark: "#ab8821",
  secondary: "#FFFFFF",
  secondaryDark: "#b6b6b6",
  bg: "#2D2E2D",
  containerBg: "rgba(0,0,0,0.85)",
  cardBg: "rgba(26,26,26,0.55)",
  cardTrim: "#3e3e3e",
  cardHighlight: "#FF0000",
  heading: "#D5AB29",
  text: "white",
  button: "#D5AB29",
  buttonHover: "#ab8821",
};

export const themeNadohe: WorkItemThemeModel = {
  navbar: "#2D3091",
  highlight: "#A3CE49",
  highlightHover: "#84a93a",
  footer: "#171919",
  deviceTrim: "#A1AAAE",
  link: "#3D78BA",
  linkHover: "#26517e",
  primary: "#3D78BA",
  primaryDark: "#26517e",
  secondary: "#1C838C",
  secondaryDark: "#176c73",
  bg: "white",
  containerBg: "transparent",
  cardBg: "transparent",
  cardTrim: "#c8c6c2",
  cardHighlight: "#FDB813",
  heading: "#3B3B3B",
  text: "#3B3B3B",
  button: "#3B78B9",
  buttonHover: "#26517e",
};

export const themeZenb: WorkItemThemeModel = {
  navbar: "#004727",
  highlight: "#FECF65",
  highlightHover: "#d3a440",
  footer: "#171919",
  deviceTrim: "#004727",
  link: "#004727",
  linkHover: "#06653a",
  primary: "#004727",
  primaryDark: "#002a17",
  secondary: "#73BE41",
  secondaryDark: "#5a9332",
  bg: "white",
  containerBg: "transparent",
  cardBg: "transparent",
  cardTrim: "black",
  cardHighlight: "#B31E2b",
  heading: "#004727",
  text: "#222222",
  button: "#004727",
  buttonHover: "#06653a",
};
