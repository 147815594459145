import React from "react";
import AnchorNoStyle from "./AnchorNoStyle";
import styled from "styled-components/macro";
import Palette from "../palette";
import { motion } from "framer-motion";
import DivFlex from "./DivFlex";
import DivMargin from "./DivMargin";

interface Props {
  highlightColor: string;
  href?: string;
  onClick?: () => void;
  title: string;
}

const NavbarLink: React.FC<Props> = (props: Props): JSX.Element => {
  const [hover, setHover] = React.useState(false);

  const variants = {
    normal: {
      width: "0%",
      transition: {
        type: "tween",
        ease: "linear",
        duration: 0.15,
      },
    },
    hover: {
      width: "100%",
      transition: {
        type: "tween",
        ease: "linear",
        duration: 0.075,
      },
    },
  };

  return (
    <LiNavbarLink
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.href ? undefined : props.onClick}
    >
      <AnchorNoStyle href={props.href}>
        <DivMargin bottom={5}>{props.title}</DivMargin>
        <DivFlex justify={"center"} align={"center"}>
          <DivUnderline
            variants={variants}
            initial={"normal"}
            animate={hover ? "hover" : "normal"}
            highlightColor={props.highlightColor}
          />
        </DivFlex>
      </AnchorNoStyle>
    </LiNavbarLink>
  );
};

const LiNavbarLink = styled.li`
  position: relative;
  top: 8px;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  font-family: "Roboto", serif;
  font-size: 17px;
  color: ${Palette.white};
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.15em;

  &:last-of-type {
    margin-right: 0;
  }
`;

const DivUnderline = styled(motion.div)<{ highlightColor: string }>`
  height: 3px;
  background-color: ${(props) => props.highlightColor};
  box-shadow: 0 0 2px ${(props) => props.highlightColor};
`;

export default NavbarLink;
