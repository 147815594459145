import React, { useContext, useEffect } from "react";
import Palette from "../palette";
import styled from "styled-components/macro";
import TylerBurkeAnimatedSvg from "./TylerBurkeAnimatedSvg";
import Fonts from "../fonts";
import { motion, useAnimation } from "framer-motion";
import DivMargin from "./DivMargin";
import DivTextAlign from "./DivTextAlign";
import ScrollIndicator from "./ScrollIndicator";
import { useScrollWaypoint } from "../hooks/useScrollWaypoint";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { px } from "../functions/px.fn";
import { translateY } from "../functions/translateY.fn";
import { scrollIntoView } from "../functions/scrollTo";
import { GlobalContext } from "./GlobalContextWrapper";

interface Props {}

const LandingScreenAnimation: React.FC<Props> = (props: Props): JSX.Element => {
  const lineHeight = 70;
  const animateFrontend = useAnimation();
  const animateBackend = useAnimation();
  const animateFullstack = useAnimation();
  const animateEngineer = useAnimation();
  const animateTextContainer = useAnimation();
  const animateHelloIm = useAnimation();
  const globalCtx = useContext(GlobalContext);
  const [showTbAnim, setShowTbAnim] = React.useState(false);
  const [showScrollIndicator, setShowScrollIndicator] = React.useState(false);
  const breakpoints = useBreakpoints();

  useEffect(() => {
    const transition = {
      delay: 0.35,
    };
    let subscribed = true;
    animateFrontend
      .start({
        translateY: px(0),
        transition,
      })
      .then(() => {
        doIfSubscribed(subscribed, () => {
          animateFrontend.start({
            translateY: px(lineHeight * -1),
            transition,
          });
          animateBackend
            .start({
              translateY: px(lineHeight * -1),
              transition,
            })
            .then(() => {
              doIfSubscribed(subscribed, () => {
                animateBackend.start({
                  translateY: px(lineHeight * -2),
                  transition,
                });
                animateFullstack.start({
                  translateY: px(lineHeight * -2),
                  transition,
                });
                animateEngineer
                  .start({
                    translateY: px(0),
                    transition: {
                      delay: 1,
                    },
                  })
                  .then(() => {
                    doIfSubscribed(subscribed, () => {
                      animateTextContainer
                        .start({
                          translateY: "-15px",
                          scale: 1,
                        })
                        .then(() => {
                          doIfSubscribed(subscribed, () => {
                            animateHelloIm
                              .start({
                                opacity: 1,
                                transition: {
                                  duration: 0.2,
                                },
                              })
                              .then(() => {
                                setTimeout(() => {
                                  setShowTbAnim(true);
                                  setTimeout(() => {
                                    setShowScrollIndicator(true);
                                  }, 2500);
                                }, 150);
                              });
                          });
                        });
                    });
                  });
              });
            });
        });
      });
    return () => {
      subscribed = false;
    };
  }, [
    animateBackend,
    animateEngineer,
    animateFrontend,
    animateFullstack,
    animateHelloIm,
    animateTextContainer,
  ]);

  const doIfSubscribed = (subscribed: boolean, cb: () => void) => {
    if (subscribed) {
      cb();
    }
  };

  const calcHellImFontSize = (): number => {
    if (breakpoints.isLg) {
      return 25;
    } else if (breakpoints.isMd) {
      return 22;
    } else {
      return 18;
    }
  };

  const calcSlidingTextFontSize = (): number => {
    if (breakpoints.isLg) {
      return 55;
    } else if (breakpoints.isMd) {
      return 40;
    } else {
      return 20;
    }
  };

  useScrollWaypoint(200, () => setShowScrollIndicator(false));

  return (
    <>
      <ScrollIndicator
        visible={showScrollIndicator}
        onClick={() => scrollIntoView(globalCtx.pageRefs.aboutMe)}
      />
      <DivContainer>
        <div>
          <DivHelloIm
            initial={{ opacity: 0 }}
            animate={animateHelloIm}
            fontSize={calcHellImFontSize()}
          >
            Hello, I'm
          </DivHelloIm>
          <DivTextAlign align={"center"}>
            <TylerBurkeAnimatedSvg started={showTbAnim} color={Palette.white} />
            <DivSlidingTextContainer
              initial={{
                translateY: breakpoints.isMd ? "-135px" : "-40px",
                scale: 1.3,
              }}
              animate={animateTextContainer}
            >
              <DivSlidingTextBlock fontSize={calcSlidingTextFontSize()}>
                <DivMargin right={!breakpoints.isMd ? 6 : 20}>
                  <DivSlidingText
                    className={"sliding-text"}
                    initial={translateY(px(lineHeight))}
                    animate={animateFrontend}
                  >
                    Frontend
                  </DivSlidingText>
                  <DivSlidingText
                    className={"sliding-text"}
                    initial={translateY(px(0))}
                    animate={animateBackend}
                  >
                    Backend
                  </DivSlidingText>
                  <DivSlidingText
                    className={"sliding-text"}
                    initial={translateY(px(lineHeight * -1))}
                    animate={animateFullstack}
                  >
                    Fullstack
                  </DivSlidingText>
                </DivMargin>
              </DivSlidingTextBlock>
              <DivSlidingTextBlock fontSize={calcSlidingTextFontSize()}>
                <DivSlidingText
                  className={"sliding-text"}
                  initial={translateY(px(lineHeight))}
                  animate={animateEngineer}
                >
                  Engineer
                </DivSlidingText>
              </DivSlidingTextBlock>
            </DivSlidingTextContainer>
          </DivTextAlign>
        </div>
      </DivContainer>
    </>
  );
};

const DivSlidingTextContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: ${Palette.white};
`;

const DivSlidingTextBlock = styled(motion.div)<{ fontSize: number }>`
  display: inline-block;
  overflow: hidden;
  height: 70px;
  line-height: 70px;

  .sliding-text {
    font-size: ${(props) => props.fontSize}px;
  }
`;

const DivSlidingText = styled(motion.div)`
  font-family: ${Fonts.playfair};
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
`;

const DivHelloIm = styled(motion.div)<{ fontSize: number }>`
  height: 25px;
  font-family: ${Fonts.roboto};
  font-size: ${(props) => props.fontSize}px;
  color: ${Palette.white};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
`;

const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LandingScreenAnimation;
