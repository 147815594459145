import * as React from "react";
import { Route, Router as BrowserRouter, Switch } from "react-router";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "./App";
import HomePage from "./pages/HomePage";
import WorkPage from "./pages/WorkPage";
import WorkEsoPage from "./pages/WorkEsoPage";
import WorkMlcPage from "./pages/WorkMlcPage";
import WorkBattleCalcPage from "./pages/WorkKebcPage";
import WorkTalkSigPage from "./pages/WorkTalkSigPage";
import WorkNadohePage from "./pages/WorkNadohePage";
import WorkZenbPage from "./pages/WorkZenbPage";

interface Props {}

const ScrollToTop: React.FC<Props> = (): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
};

const Router: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <BrowserRouter history={history}>
      <ScrollToTop />
      <Switch>
        <Route path={"/work"} exact>
          <WorkPage />
        </Route>
        <Route path={"/work/esolfg"} exact>
          <WorkEsoPage />
        </Route>
        <Route path={"/work/talking-significance"} exact>
          <WorkTalkSigPage />
        </Route>
        <Route path={"/work/nadohe"} exact>
          <WorkNadohePage />
        </Route>
        <Route path={"/work/zenb"} exact>
          <WorkZenbPage />
        </Route>
        <Route path={"/work/mlc"} exact>
          <WorkMlcPage />
        </Route>
        <Route path={"/work/kebc"} exact>
          <WorkBattleCalcPage />
        </Route>
        <Route path={"*"}>
          <HomePage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
