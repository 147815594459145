import React, { useEffect } from "react";
import { setConfiguration } from "react-grid-system";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import Palette from "./palette";
import GlobalContextWrapper from "./components/GlobalContextWrapper";
import LoadingIndicator from "./components/LoadingIndicator";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createHashHistory } from "history";
import Router from "./Router";

setConfiguration({
  gutterWidth: 0,
});

Sentry.init({
  dsn:
    "https://4fe960201cbf4914a65548042638f2f6@o513779.ingest.sentry.io/5616248",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1,
});

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: Palette.secondaryLight,
      main: Palette.secondary,
      dark: Palette.secondaryDark,
    },
  },
});

export const history = createHashHistory({ hashType: "slash" });

function App() {
  useEffect(() => {
    const loadingEl = document.getElementById("loading-screen");
    if (loadingEl) {
      loadingEl.remove();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalContextWrapper>
        <LoadingIndicator />
        <Router />
      </GlobalContextWrapper>
    </ThemeProvider>
  );
}

export default App;
