import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { IoIosClose } from "react-icons/io";
import Icon from "./Icon";
import Palette from "../palette";
import { motion, useAnimation } from "framer-motion";
import DivFlex from "./DivFlex";

interface Props {
  src: string;
  onClose: () => void;
  animateDir: "right" | "left";
}

const FullScreenImage: React.FC<Props> = (props: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animateBg = useAnimation();
  const animateImg = useAnimation();

  useEffect(() => {
    animateBg
      .start({
        opacity: 1,
        width: "100vw",
        transition: {
          duration: 0.35,
          delay: 0,
        },
      })
      .then(() => {
        animateImg.start({
          scale: 1,
          translateX: "0px",
          opacity: 1,
          transition: {
            duration: 0.35,
            delay: 0,
          },
        });
      });
  }, [animateBg, animateImg]);

  const close = () => {
    animateImg
      .start({
        scale: 0.3,
        translateX: props.animateDir === "right" ? "300px" : "-300px",
        opacity: 0,
        transition: {
          duration: 0.35,
          delay: 0,
        },
      })
      .then(() => {
        animateBg
          .start({
            opacity: 0,
            width: "0vw",
            transition: {
              duration: 0.35,
              delay: 0,
            },
          })
          .then(() => {
            props.onClose();
          });
      });
  };

  const onEscapeDown = (event: any) => {
    if (event.code === "Escape") {
      props.onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", onEscapeDown);

    return () => document.removeEventListener("keyup", onEscapeDown);
  });

  return (
    <DivFsContainer ref={containerRef} onKeyUp={onEscapeDown}>
      <DivBg initial={{ opacity: 0, width: "0vw" }} animate={animateBg}>
        <DivFlex align={"center"} justify={"center"}>
          <ImgFs
            initial={{
              opacity: 0,
              scale: 0.3,
              translateX: props.animateDir === "right" ? "300px" : "-300px",
            }}
            animate={animateImg}
            src={props.src}
            alt={"Full screen image"}
          />
        </DivFlex>
      </DivBg>
      <DivCloseContainer onClick={close}>
        <Icon
          icon={IoIosClose}
          color={Palette.white}
          hoverColor={Palette.secondaryLight}
          title={"Exit full screen mode"}
          size={55}
        />
      </DivCloseContainer>
    </DivFsContainer>
  );
};

const DivFsContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

const DivBg = styled(motion.div)`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
`;

const DivCloseContainer = styled(motion.div)`
  cursor: pointer;
  display: flex;
  position: fixed;
  top: 50px;
  right: 50px;
  justify-content: flex-end;
  border-radius: 50%;
  background-color: black;
`;

const ImgFs = styled(motion.img)`
  height: 100vh;
  width: auto;
`;

export default FullScreenImage;
