import styled from "styled-components/macro";

interface Props {
  padding?: number;
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
}

const DivPadding = styled.div<Props>`
    ${props => (props.padding ? `padding: ${props.padding}px;` : undefined)}
    ${props => (props.top ? `padding-top: ${props.top}px;` : undefined)}
    ${props =>
      props.bottom ? `padding-bottom: ${props.bottom}px;` : undefined}
    ${props => (props.left ? `padding-left: ${props.left}px;` : undefined)}
    ${props => (props.right ? `padding-right: ${props.right}px;` : undefined)}
`;

export default DivPadding;
