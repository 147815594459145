import React from "react";
import WorkItemLayout from "../layouts/WorkItemLayout";
import { themeMlc } from "../themes";
import { contentBasePath } from "../constants";
import { useBreakpoints } from "../hooks/useBreakpoints";
import Anchor from "../components/Anchor";
import SpecialText from "../components/SpecialText";
import { SiJava, SiSpring } from "react-icons/si";
import { FaReact } from "react-icons/fa";

interface Props {}

const WorkMlcPage: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  return (
    <WorkItemLayout
      workItem={{
        theme: themeMlc,
        logoSrc: `${contentBasePath}/images/mlc-logo.jpg`,
        logoTitle: "MLC Logo",
        name: "MLC Navigator/Wrap",
        year: "2019",
        description: (
          <>
            The goal of this project was to rebuild the secure Navigator/Wrap
            product customer and adviser portals for MLC. The existing
            architecture was a monolithic&nbsp;
            <SpecialText
              icon={SiJava}
              iconColor={"#ff8d1f"}
              href={"https://go.java/?intcmp=gojava-banner-java-com"}
            >
              Java
            </SpecialText>
            &nbsp;based&nbsp;
            <Anchor
              color={themeMlc.link}
              hoverColor={themeMlc.linkHover}
              href={"https://struts.apache.org/"}
            >
              Struts 2
            </Anchor>
            &nbsp;application reaching the end of its lifecycle and the
            organisation wanted to decommission this platform, in its place
            standing up a stateless microservice architecture. We were able to
            achieve this over many months by applying the&nbsp;
            <Anchor
              color={themeMlc.link}
              hoverColor={themeMlc.linkHover}
              href={
                "https://docs.microsoft.com/en-us/azure/architecture/patterns/strangler-fig"
              }
            >
              Strangler Fig pattern
            </Anchor>
            . We ended up with two&nbsp;
            <SpecialText
              icon={FaReact}
              iconColor={"#61DAFB"}
              href={"https://reactjs.org/"}
            >
              React
            </SpecialText>
            &nbsp;SPAs, 6&nbsp;
            <SpecialText
              icon={SiSpring}
              iconColor={"#6DB340"}
              href={"https://spring.io/projects/spring-boot"}
            >
              Spring Boot
            </SpecialText>
            &nbsp;service engines, and over 40&nbsp;
            <SpecialText
              src={`${contentBasePath}/images/lambda-logo.svg`}
              href={"https://aws.amazon.com/lambda/"}
            >
              Lambda
            </SpecialText>
            &nbsp;functions (plus additional microservice plumbing like SQS
            queues and DynamoDB tables). As a result of this architectural
            shift, the organisation enjoyed many benefits, keep reading to find
            out more.
          </>
        ),
        splashSrc: `${contentBasePath}/images/mlc-splash.jpg`,
        splashPosition: "top right",
        showcases: [
          {
            bgSrc: `${contentBasePath}/images/mlc-mobile-splash.jpg`,
            phoneOne: {
              id: "screen-1",
              src: `${contentBasePath}/images/mlc-mobile-2.png`,
              title: "Screenshot of Home screen on mobile",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeMlc.deviceTrim,
            },
            phoneTwo: {
              id: "screen-2",
              src: `${contentBasePath}/images/mlc-mobile-1.png`,
              title: "Screenshot of Data Export screen on mobile",
              animate: breakpoints.isMd,
              rotateDirection: "left",
              trimColor: themeMlc.deviceTrim,
            },
            phoneThree: {
              id: "screen-3",
              src: `${contentBasePath}/images/mlc-mobile-3.png`,
              title: "Screenshot of Stock Trading screen on mobile",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeMlc.deviceTrim,
            },
          },
        ],
        infoBlocks: [
          {
            cards: [
              {
                heading: "Reliability, Scalability, Cost-effectiveness",
                body: (
                  <>
                    These things all improved improved significantly - more
                    managed services with built in fault tolerance, elasticity
                    and a pay-per-use model mean that: 1) less time is spend
                    maintaining infrastructure, more on development; 2) when no
                    one is using your site, you don't pay.
                  </>
                ),
              },
              {
                heading: "Daytime Deployments",
                body: (
                  <>
                    Deployments went from huge releases requiring downtime and
                    with many moving parts to simple 30 minute exercises that
                    could be done in the middle of the day thanks to a stateless
                    architecture. Because no state is persisted in running apps,
                    they can be replaced at any time with no impact to user
                    sessions.
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/mlc-browser-1.jpg`,
            imgTitle: "Screenshot of Home screen on desktop",
          },
          {
            cards: [
              {
                heading: "No More Quaqmires",
                body: (
                  <>
                    Developers were no longer tripping over themselves stuck in
                    a quagmire of different branches and release timings, a
                    distributed system means a separation of code and scope that
                    allows devs to split work much more effectively and speed up
                    the development lifecycle.
                  </>
                ),
              },
              {
                heading: "Feature Toggles",
                body: (
                  <>
                    We needed to maintain both the old site and the new site at
                    the same time as we gradually rolled out our changes to
                    customers. Enter&nbsp;
                    <SpecialText
                      src={`${contentBasePath}/images/ld-logo.png`}
                      href={"https://launchdarkly.com/"}
                    >
                      LaunchDarkly
                    </SpecialText>
                    . Using feature toggles we can control with the switch of a
                    button what features and experiences a user can see. This
                    approach allows the organisation to perform A/B testing and
                    to deploy new features in stages or gradually to a
                    percentage of users over time.
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/mlc-browser-2.jpg`,
            imgTitle: "Screenshot of Reports screen on desktop",
          },
        ],
      }}
    />
  );
};

export default WorkMlcPage;
