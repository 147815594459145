import React from "react";
import styled from "styled-components/macro";
import AnchorNoStyle from "./AnchorNoStyle";

interface Props {
  color: string;
  size: number;
  title?: string;
  href?: string;
  onClick?: () => void;
  hoverColor?: string;
}

const Logo: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <AnchorNoStyle href={props.href} title={props.title}>
        <DivContainer
          {...props}
          onClick={props.href ? undefined : props.onClick}
        >
          <SvgLogo
            id="tb-logo"
            data-name="tb-logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 230 230"
          >
            <path
              d="M116.542,196.71H101.271V74.542H55.458V44H70.729V59.271h45.813V181.439h15.271V196.71ZM162.355,59.271h15.271V74.542H131.813V59.271h30.542Z"
              transform="translate(-1.5 -2.5)"
            />
            <rect x="4" y="4" width="222" height="222" />
          </SvgLogo>
        </DivContainer>
      </AnchorNoStyle>
    </>
  );
};

const DivContainer = styled.div<{
  color: string;
  size: number;
  href?: string;
  onClick?: () => void;
  hoverColor?: string;
}>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  cursor: ${(props) => (props.href || props.onClick ? "pointer" : undefined)};
  user-select: none;

  svg {
    stroke: ${(props) => props.color};
  }

  &:hover {
    svg {
      stroke: ${(props) => props.hoverColor};
    }
  }
`;

const SvgLogo = styled.svg`
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 8px;
`;

export default Logo;
