import { motion, useAnimation } from "framer-motion";
import React, { RefObject, useContext, useEffect } from "react";
import styled from "styled-components/macro";
import Palette from "../palette";
import { Col, Row } from "react-grid-system";
import Logo from "./Logo";
import DivFlex from "./DivFlex";
import EmailLink from "./EmailLink";
import Button from "./Button";
import DivMargin from "./DivMargin";
import NavbarLink from "./NavbarLink";
import UlNoBullets from "./UlNoBullets";
import Icon from "./Icon";
import { IoIosClose } from "react-icons/io";
import { GlobalContext } from "./GlobalContextWrapper";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { useHistory } from "react-router-dom";
import { goToAnchor } from "../functions/goToAnchor.fn";
import { contentBasePath } from "../constants";

interface Props {
  highlightColor: string;
}

const MenuModal: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx = useContext(GlobalContext);
  const animateBg = useAnimation();
  const [showMenu, setShowMenu] = React.useState(false);
  const breakpoints = useBreakpoints();
  const history = useHistory();

  useEffect(() => {
    animateBg
      .start({
        scale: 40,
        transition: {
          duration: 0.35,
          ease: "easeIn",
        },
      })
      .then(() => {
        setShowMenu(() => true);
      });
  }, [animateBg, globalCtx]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {document.body.style.overflow = "auto"}
  }, []);

  const closeMenu = (cb?: () => void) => {
    setShowMenu(() => false);
    animateBg
      .start({
        scale: 0,
        transition: {
          duration: 0.35,
          ease: "easeOut",
        },
      })
      .then(() => {
        globalCtx.setIsMenuOpen(() => false);
        if (cb) {
          cb();
        }
      });
  };

  const goToRef = (ref: RefObject<HTMLDivElement>) => {
    closeMenu(() => goToAnchor(history, "/", ref));
  };

  const goToLink = (path: string) => {
    closeMenu(() => history.push(path));
  };

  return (
    <>
      <DivGrowingCircle
        initial={{ width: "55px", height: "55px" }}
        animate={animateBg}
        isXl={breakpoints.isXl}
      />
      <DivContainer visible={showMenu}>
        <DivLogo isXl={breakpoints.isXl}>
          <Logo color={Palette.white} size={40} href={"/"} />
        </DivLogo>
        <DivExit isXl={breakpoints.isXl}>
          <Icon
            icon={IoIosClose}
            title={"Close menu"}
            size={55}
            color={Palette.white}
            hoverColor={Palette.secondaryLight}
            onClick={() => closeMenu()}
          />
        </DivExit>
        <Row align={"center"}>
          <Col xl={4}>
            <DivSection
              align={breakpoints.isXl ? "center" : "flex-end"}
              justify={breakpoints.isXl ? "flex-end" : "center"}
              vh={breakpoints.isXl ? 100 : 57}
            >
              <UlLinks>
                <NavbarLink
                  onClick={() => goToLink("/")}
                  title={"Home"}
                  highlightColor={props.highlightColor}
                />
                <NavbarLink
                  onClick={() => goToRef(globalCtx.pageRefs.aboutMe)}
                  title={"Who"}
                  highlightColor={props.highlightColor}
                />
                <NavbarLink
                  onClick={() => goToRef(globalCtx.pageRefs.services)}
                  title={"What"}
                  highlightColor={props.highlightColor}
                />
                <NavbarLink
                  onClick={() => goToLink("/work")}
                  title={"Work"}
                  highlightColor={props.highlightColor}
                />
                {/*<NavbarLink*/}
                {/*  onClick={() => goToRef(globalCtx.pageRefs.experience)}*/}
                {/*  title={"Experience"}*/}
                {/*  highlightColor={props.highlightColor}*/}
                {/*/>*/}
                <NavbarLink
                  onClick={() => goToRef(globalCtx.pageRefs.contact)}
                  title={"Contact"}
                  highlightColor={props.highlightColor}
                />
              </UlLinks>
            </DivSection>
          </Col>
          {breakpoints.isXl && (
            <Col xl={4}>
              <DivFlex
                justify={"center"}
                align={breakpoints.isXl ? "center" : "flex-start"}
              >
                <DivSeparator />
              </DivFlex>
            </Col>
          )}
          <Col xl={4}>
            <DivSection
              align={"center"}
              justify={breakpoints.isXl ? "flex-start" : "center"}
              vh={breakpoints.isXl ? 100 : 43}
            >
              <div>
                <DivMargin bottom={breakpoints.isXl ? 75 : 40}>
                  <Button
                    onClick={() =>
                      window.open(`${contentBasePath}/resume.pdf`, "_blank")
                    }
                  >
                    Download Resume
                  </Button>
                </DivMargin>
                <EmailLink fontSize={25} highlightThickness={2} />
              </div>
            </DivSection>
          </Col>
        </Row>
      </DivContainer>
    </>
  );
};

const DivContainer = styled(motion.div)<{ visible: boolean }>`
  position: fixed;
  display: ${(props) => (!props.visible ? "none" : undefined)};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Palette.footer};
  z-index: 5;
`;

const DivSection = styled(DivFlex)<{ vh: number }>`
  height: ${(props) => props.vh}vh;
`;

const DivLogo = styled.div<{ isXl: boolean }>`
  position: fixed;
  z-index: 6;
  top: ${(props) => (props.isXl ? 35 : 25)}px;
  left: ${(props) => (props.isXl ? 70 : 30)}px;
`;

const DivExit = styled.div<{ isXl: boolean }>`
  position: fixed;
  top: ${(props) => (props.isXl ? 35 : 20)}px;
  right: ${(props) => (props.isXl ? 70 : 20)}px;
  z-index: 6;
  cursor: pointer;
`;

const DivGrowingCircle = styled(motion.div)<{ isXl: boolean }>`
  position: fixed;
  z-index: 5;
  right: ${(props) => (props.isXl ? 70 : 30)}px;
  top: ${(props) => (props.isXl ? 40 : 20)}px;
  border-radius: 50%;
  background-color: ${Palette.footer};
`;

const UlLinks = styled(UlNoBullets)`
  li {
    margin-bottom: 20px;
    float: left;
    clear: left;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const DivSeparator = styled.div`
  width: 1px;
  height: 300px;
  background-color: ${Palette.secondaryDark};
`;

export default MenuModal;
