import styled from "styled-components/macro";

const DivSection = styled.div`
  @media (max-width: 575px) {
    padding: 50px 0;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 75px 0;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 95px 0;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 115px 0;
  }
  padding: 140px 0;
  backface-visibility: hidden;
`;

export default DivSection;
