import React from "react";
import WorkItemLayout from "../layouts/WorkItemLayout";
import { themeKebc } from "../themes";
import { contentBasePath } from "../constants";
import { useBreakpoints } from "../hooks/useBreakpoints";
import Anchor from "../components/Anchor";
import SpanRobotoFont from "../components/SpanRobotoFont";
import DivMargin from "../components/DivMargin";
import SpecialText from "../components/SpecialText";

interface Props {}

const WorkEsoPage: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  return (
    <WorkItemLayout
      workItem={{
        designedByMe: true,
        theme: themeKebc,
        logoSrc: `${contentBasePath}/images/kebc-logo.jpg`,
        logoTitle: "BattleCalc Logo",
        name: "King's Empire BattleCalc",
        year: "2014",
        description: (
          <>
            King's Empire BattleCalc was a calculator I developed to help
            players of a very popular iOS MMO,&nbsp;
            <Anchor
              color={themeKebc.link}
              hoverColor={themeKebc.linkHover}
              href={"https://apps.apple.com/au/app/kings-empire/id484039314"}
            >
              King’s Empire
            </Anchor>
            , overcome their enemies with superior planning and knowledge
            available through detailed analysis of battles before they happened.
            It was developed in&nbsp;
            <SpecialText
              src={`${contentBasePath}/images/csharp-logo.svg`}
              href={"https://docs.microsoft.com/en-us/dotnet/csharp/"}
            >
              C#
            </SpecialText>
            &nbsp;using the&nbsp;
            <Anchor
              color={themeKebc.link}
              hoverColor={themeKebc.linkHover}
              href={"https://docs.microsoft.com/en-us/xamarin/ios/"}
            >
              Xamarin.iOS
            </Anchor>
            &nbsp;and&nbsp;
            <Anchor
              color={themeKebc.link}
              hoverColor={themeKebc.linkHover}
              href={"https://developer.apple.com/xcode/"}
            >
              XCode
            </Anchor>
            &nbsp;SDKs, released to the iOS AppStore in 2014.
            <DivMargin top={10}>
              <SpanRobotoFont
                size={"20px"}
                color={themeKebc.text}
                weight={"bold"}
              >
                Please note:
              </SpanRobotoFont>
              &nbsp; KEBC was a project I started in an attempt to learn iOS
              development and the C# language while I was still in university.
              In reality it was my first shot at putting together a cohesive
              application. It's been decommissioned for many years now, but the
              internet never forgets! Thanks to the&nbsp;
              <Anchor
                color={themeKebc.link}
                hoverColor={themeKebc.linkHover}
                href={"https://archive.org/web/"}
              >
                Wayback Machine
              </Anchor>
              &nbsp;I was able to go back in time and get some material,
              apologies if some of the screenshots are low quality.
            </DivMargin>
          </>
        ),
        splashSrc: `${contentBasePath}/images/kebc-splash.jpg`,
        splashPosition: "top center",
        showcases: [
          {
            bgSrc: `${contentBasePath}/images/kebc-mobile-splash.jpg`,
            phoneOne: {
              id: "screen-1",
              src: `${contentBasePath}/images/kebc-mobile-1.png`,
              title: "Screenshot of Home screen on mobile",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeKebc.deviceTrim,
            },
            phoneTwo: {
              id: "screen-2",
              src: `${contentBasePath}/images/kebc-mobile-2.png`,
              title: "Screenshot of Army screen on mobile",
              animate: breakpoints.isMd,
              rotateDirection: "left",
              trimColor: themeKebc.deviceTrim,
            },
            phoneThree: {
              id: "screen-3",
              src: `${contentBasePath}/images/kebc-mobile-3.png`,
              title: "Screenshot of Battle Report screen on mobile",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeKebc.deviceTrim,
            },
          },
        ],
        infoBlocks: [
          {
            cards: [
              {
                heading: "Persistent Data",
                body: (
                  <>
                    Users can load different saved enemy and ally ‘profiles’ to
                    quickly switch between different battle scenarios without
                    the need to re-input data.
                  </>
                ),
              },
              {
                heading: "Share Data",
                body: (
                  <>
                    Users can import/export their saved ‘profiles’ or battle
                    outcome reports to other users of KEBC they wanted to
                    collaborate with. I created a small website including an
                    online forum so that users of KEBC and King’s Empire could
                    share in-game strategies, report bugs or request new
                    features
                  </>
                ),
              },
              {
                heading: "Website/Forum",
                body: (
                  <>
                    I created a small website using cPanel including an online
                    forum so that users of KEBC and King’s Empire could share
                    in-game strategies, report bugs or request new features.
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/kebc-browser-1.jpg`,
            imgTitle: "Screenshot of Home screen on desktop",
          },
        ],
      }}
    />
  );
};

export default WorkEsoPage;
