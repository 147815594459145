const Palette = {
  bg: "#292132",
  white: "#ffffff",
  bgCardLight: "#38324c",
  bgCard: "#2f293c",
  bgCardDark: "#2c2639",
  primaryLight: "#ae3efd",
  primary: "#A326FC",
  primaryDark: "#8e21d9",
  tertiaryDark: "#cb0257",
  tertiary: "#E80262",
  tertiaryLight: "#ff006b",
  secondaryDark: "#25729f",
  secondary: "#3096D3",
  secondaryLight: "#35abf1",
  footer: "#232532",
  success: "#178f43",
  error: "#e00101",
};

export default Palette;
