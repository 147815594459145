import React from "react";
import { ServiceModel } from "../models/service.model";
import { motion } from "framer-motion";
import DivCard from "./DivCard";
import Palette from "../palette";
import SpanRobotoFont from "./SpanRobotoFont";
import Waypoint from "./Waypoint";
import styled from "styled-components/macro";
import DivFlex from "./DivFlex";
import { useBreakpoints } from "../hooks/useBreakpoints";

interface Props {
  service: ServiceModel;
}

const ServiceCard: React.FC<Props> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const breakpoints = useBreakpoints();

  const variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <Waypoint onEnter={() => setVisible(true)} threshold={0.3}>
      <motion.div
        variants={variants}
        initial={"hidden"}
        animate={visible ? "visible" : "hidden"}
      >
        <DivHoverCard className={"service-card"}>
          <DivFlex align={"flex-end"}>
            <DivHighlight />
            <SpanRobotoFont
              as={"div"}
              size={breakpoints.isSm ? "30px" : "25px"}
              color={Palette.white}
              weight={"bold"}
            >
              {props.service.name}
            </SpanRobotoFont>
          </DivFlex>
          <SpanRobotoFont
            as={"div"}
            color={Palette.white}
            size={breakpoints.isSm ? "20px" : "15px"}
          >
            {props.service.description}
          </SpanRobotoFont>
        </DivHoverCard>
      </motion.div>
    </Waypoint>
  );
};

const DivHighlight = styled.div`
  background-color: ${Palette.tertiary};
  width: 3px;
  height: 15px;
  position: relative;
  bottom: 10px;
  margin-right: 5px;
`;

const DivHoverCard = styled(DivCard)`
  backface-visibility: hidden;
  position: relative;
  display: inline-block;
  z-index: 2;
  transition: all 500ms ease-out;
  padding: 25px;
`;

export default ServiceCard;
