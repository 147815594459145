import React from "react";
import styled from "styled-components/macro";
import Palette from "../palette";
import { IconType } from "react-icons";

interface Props {
  icon: IconType;
  title: string;
  color?: string;
  href?: string;
  onClick?: () => void;
  hoverColor?: string;
  size?: number;
  target?: string;
}

const Icon: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <DivContainer
      className={"react-icon"}
      color={props.color || Palette.white}
      hoverColor={props.hoverColor}
      size={props.size || 25}
      title={props.title}
    >
      {props.href ? (
        <a href={props.href} target={props.target}>
          <props.icon />
        </a>
      ) : (
        <props.icon onClick={props.onClick} />
      )}
    </DivContainer>
  );
};

const DivContainer = styled.div<{
  color: string;
  size: number;
  hoverColor?: string;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transition: all 500ms ease-out;
    position: relative;
    display: block;
    color: ${(props) => props.color};
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
  }

  a {
    &:before {
      transition: all 500ms ease-out;
      opacity: 0;
      content: "";
      display: block;
      background-color: purple;
      position: absolute;
      width: 5px;
      height: 5px;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      box-shadow: 0 0 20px 25px ${(props) => props.hoverColor};
    }
  }

  ${(props) =>
    props.hoverColor
      ? `
    &:hover {
    a {
      &:before {
        transition: all 50ms ease-in;
        opacity: 0.1;
      }
    }
     svg {
       transition: all 50ms ease-in;
       color: ${props.hoverColor};
     } 
    }
  `
      : undefined}
`;

export default Icon;
