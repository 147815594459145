import styled from "styled-components/macro";
import Fonts from "../fonts";
import { calcWeight } from "../functions/calcWeight.fn";
import { FontWeightModel } from "../models/fontWeight.model";

const SpanRobotoFont = styled.span<{
  size: string;
  color: string;
  weight?: FontWeightModel;
  flat?: boolean;
}>`
  font-family: ${Fonts.roboto};
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  font-weight: ${(props) => calcWeight(props.weight!)};
  -webkit-font-smoothing: antialiased;
  line-height: ${(props) => (props.flat ? undefined : "1.4em")};
`;

export default SpanRobotoFont;
