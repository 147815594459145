import React from "react";
import WorkItemLayout from "../layouts/WorkItemLayout";
import { themeEso, themeTalkSig } from "../themes";
import { contentBasePath } from "../constants";
import { useBreakpoints } from "../hooks/useBreakpoints";
import Anchor from "../components/Anchor";

interface Props {}

const WorkTalkSigPage: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  return (
    <WorkItemLayout
      workItem={{
        theme: themeTalkSig,
        logoSrc: `${contentBasePath}/images/talksig-logo.png`,
        logoTitle: "Talking Significance",
        name: "Talking Significance",
        year: "2021",
        webLink: "https://talkingsignificance.com",
        description: (
          <>
            The Talking Significance website is a on-boarding and relationship
            management tool for clients of a financial advisory firm. The
            on-boarding experience consists of an interactive dialogue that
            financial advisors use to help clients uncover their true financial
            goals and prioritise whats most important for their financial
            future. After completing a dialogue the client is presented with a
            report-card including a list of follow up tasks with check-in dates,
            advisors can view and update previous dialogues to maintain a
            relationship with their clients.
          </>
        ),
        splashSrc: `${contentBasePath}/images/talksig-splash.jpg`,
        splashPosition: "top center",
        showcases: [
          {
            bgSrc: `${contentBasePath}/images/talksig-mobile-splash.jpg`,
            phoneOne: {
              id: "screen-1",
              src: `${contentBasePath}/images/talksig-mobile-3.png`,
              title: "Screenshot of Talking Significance website",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeTalkSig.deviceTrim,
            },
            phoneTwo: {
              id: "screen-2",
              src: `${contentBasePath}/images/talksig-mobile-1.png`,
              title: "Screenshot of Talking Significance website",
              animate: breakpoints.isMd,
              rotateDirection: "left",
              trimColor: themeTalkSig.deviceTrim,
            },
            phoneThree: {
              id: "screen-3",
              src: `${contentBasePath}/images/talksig-mobile-2.png`,
              title: "Screenshot of Talking Significance website",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeTalkSig.deviceTrim,
            },
          },
        ],
        infoBlocks: [
          {
            cards: [
              {
                heading: "Authentication and Security",
                body: (
                  <>
                    The website uses&nbsp;
                    <Anchor
                      color={themeEso.link}
                      hoverColor={themeEso.linkHover}
                      href={"https://aws.amazon.com/cognito/"}
                    >
                      AWS Cognito
                    </Anchor>
                    &nbsp;for authentication and user management - mobile
                    multi-factor authentication is enabled for enhanced
                    security. Using a custom built self-service admin portal,
                    managers can easily provision and manage advisor accounts.
                  </>
                ),
              },
              {
                heading: "Client Dialogues",
                body: (
                  <>
                    Advisors can have meaningful conversations with their
                    clients and guide them through the process of prioritising
                    their financial goals with an easy to use and intuitive drag
                    and drop interface with cards representing financial goals
                    and decks representing different categories.
                  </>
                ),
              },
              {
                heading: "Linked Clients",
                body: (
                  <>
                    Client's often have spouses or partners who need to part of
                    financial planning. A Talking Significance dialogue allows
                    two clients to be linked and each client's goals to be
                    captured. At the end of the dialogue a set of shared goals
                    is created that best fits both clients.
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/talksig-browser-2.jpg`,
            imgTitle: "Screenshot of Talking Significance website",
          },
          {
            cards: [
              {
                heading: "Report Cards",
                body: (
                  <>
                    Once a dialogue has been completed a .PDF report-card is
                    generated and provided to the client as a record of their
                    agreed upon goals, priorities and actions.
                  </>
                ),
              },
              {
                heading: "Action Tracker",
                body: (
                  <>
                    A list of actions can be associated with a given client
                    dialogue, these actions can be recorded and tracked over
                    time using the action tracker. This allows advisors to stay
                    up to date with the progress of their clients and manage the
                    relationship effectively.
                  </>
                ),
              },
              {
                heading: "CRM",
                body: (
                  <>
                    Advisors can use their dashboard to create new client
                    profiles, search their existing client database and keep
                    their customer's details up to date.
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/talksig-browser-1.jpg`,
            imgTitle: "Screenshot of Talking Significance website",
          },
        ],
      }}
    />
  );
};

export default WorkTalkSigPage;
