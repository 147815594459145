import { motion } from "framer-motion";
import styled from "styled-components/macro";
import Palette from "../palette";

const DivCard = styled(motion.div)<{ width?: string; height?: string }>`
  background-color: ${Palette.bgCard};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 50px;

  @media (max-width: 768px) {
    padding: 20px;
    border-radius: 0;
    margin: 0 10px;
  }
`;

export default DivCard;
