import { useEffect } from "react";
import { useViewportScroll } from "framer-motion";

export function useScrollWaypoint(yPos: number, fn: () => void): void {
  const { scrollY } = useViewportScroll();

  useEffect(() => {
    const unsub = scrollY.onChange((yVal) => {
      if (yVal > yPos) {
        fn();
      }
    });
    return () => unsub();
  }, [fn, scrollY, yPos]);
}
