import React, { useContext, useMemo } from "react";
import { Col, Row } from "react-grid-system";
import styled from "styled-components/macro";
import Palette from "../palette";
import SpanRobotoFont from "./SpanRobotoFont";
import Fonts from "../fonts";
import AnchorNoStyle from "./AnchorNoStyle";
import Logo from "./Logo";
import DivFlex from "./DivFlex";
import { scrollIntoView } from "../functions/scrollTo";
import { GlobalContext } from "./GlobalContextWrapper";
import { BreakpointsModel, useBreakpoints } from "../hooks/useBreakpoints";
import { githubUrl, linkedInUrl } from "../constants";

interface Props {
  bgColor: string;
  highlightColor: string;
  highlightHoverColor: string;
}

const Footer: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx = useContext(GlobalContext);
  const breakpoints = useBreakpoints();

  const now = useMemo(() => new Date(), []);

  return (
    <DivContainer {...props}>
      <Row>
        <Col xs={12} md={4}>
          <DivDisclaimer breakpoints={breakpoints}>
            <SpanRobotoFont size={"15px"} color={Palette.white}>
              Wirt's Leg Pty Ltd © {now.getFullYear()}
            </SpanRobotoFont>
          </DivDisclaimer>
        </Col>
        <Col xs={12} md={4}>
          <DivLogoContainer justify={"center"} align={"center"}>
            <Logo
              color={Palette.white}
              size={40}
              hoverColor={Palette.white}
              title={"Go to top"}
              onClick={() => scrollIntoView(globalCtx.pageRefs.top)}
            />
          </DivLogoContainer>
        </Col>
        <Col xs={12} md={4}>
          <DivSocialContainer breakpoints={breakpoints}>
            <UlSocialLink
              highlightColor={props.highlightColor}
              hoverHighlightColor={props.highlightHoverColor}
            >
              <AnchorNoStyle href={linkedInUrl} target={"_blank"}>
                LinkedIn
              </AnchorNoStyle>
            </UlSocialLink>
            <UlSocialLink
              highlightColor={props.highlightColor}
              hoverHighlightColor={props.highlightHoverColor}
            >
              <AnchorNoStyle href={githubUrl} target={"_blank"}>
                Github
              </AnchorNoStyle>
            </UlSocialLink>
          </DivSocialContainer>
        </Col>
      </Row>
    </DivContainer>
  );
};

const DivContainer = styled.div<{ bgColor: string }>`
  width: 100%;
  background-color: ${(props) => props.bgColor};
  backface-visibility: hidden;
`;

const DivLogoContainer = styled(DivFlex)`
  height: 100%;
`;

const DivDisclaimer = styled.div<{ breakpoints: BreakpointsModel }>`
  display: flex;
  align-items: center;
  height: 150px;
  justify-content: ${(props) =>
    props.breakpoints.isMd ? "flex-start" : "center"};
  margin-left: ${(props) => (props.breakpoints.isMd ? 50 : 0)}px;
`;

const DivSocialContainer = styled.div<{ breakpoints: BreakpointsModel }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.breakpoints.isMd ? "flex-end" : "center"};
  height: 150px;
  margin-right: ${(props) => (props.breakpoints.isMd ? 50 : 0)}px;
`;

const UlSocialLink = styled.div<{
  highlightColor: string;
  hoverHighlightColor: string;
}>`
  cursor: pointer;
  font-family: ${Fonts.roboto};
  font-size: 15px;
  margin-right: 20px;
  border-bottom: 2px solid ${(props) => props.highlightColor};
  transition: all 250ms ease-out;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    border-bottom: 2px solid ${(props) => props.hoverHighlightColor};
    transition: all 75ms ease-in;
  }
`;

export default Footer;
