import { RefObject, useLayoutEffect, useState } from "react";
import { getElEndYPos } from "../functions/getElEndYPos.fn";
import { getElStartYPos } from "../functions/getElStartYPos.fn";

export function useRefScrollProgress(
  ref: RefObject<any>
): { ref: RefObject<any>; start: number; end: number } {
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    setStart(getElStartYPos(ref.current));
    setEnd(getElEndYPos(ref.current));
  }, [ref]);
  return { ref, start, end };
}
