import styled from "styled-components/macro";

const DivAbstractLandscape = styled.div<{
  imgSrc: string;
  bgPos: string;
  viewHeight: number;
}>`
  height: ${(props) => props.viewHeight}vh;
  width: 100%;
  background-image: url("${(props) => props.imgSrc}");
  background-position: ${(props) => props.bgPos};
  background-repeat: no-repeat;
  background-size: cover;
  backface-visibility: hidden;
`;

export default DivAbstractLandscape;
