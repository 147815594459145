import React, { useRef } from "react";
import styled from "styled-components/macro";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { useRefScrollProgress } from "../hooks/useRefScrollProgress";
import { PhoneMockupModel } from "../models/phoneMockup.model";

interface Props {
  phone: PhoneMockupModel;
  onClick?: () => void;
}

const PhoneMockup: React.FC<Props> = (props: Props): JSX.Element => {
  const rotateRight = props.phone.rotateDirection === "right";
  const refContainer = useRef<HTMLDivElement>(null);
  const { scrollY } = useViewportScroll();
  const { start, end } = useRefScrollProgress(refContainer);
  const rotateScreen = useTransform(
    scrollY,
    [start, end],
    [rotateRight ? "-25deg" : "25deg", rotateRight ? "35deg" : "-35deg"]
  );
  const rotateScreenBg = useTransform(
    scrollY,
    [start, end],
    [rotateRight ? "-20deg" : "20deg", rotateRight ? "30deg" : "-30deg"]
  );
  const translateScreenBg = useTransform(
    scrollY,
    [start, end],
    [rotateRight ? "-20px" : "20px", rotateRight ? "20px" : "-20px"]
  );
  const translatePhoneDown = useTransform(
    scrollY,
    [start, end],
    ["0px", rotateRight ? "60px" : "0px"]
  );

  return (
    <DivContainer
      ref={refContainer}
      id={props.phone.id}
      style={
        props.phone.animate ? { translateY: translatePhoneDown } : undefined
      }
      onClick={props.onClick}
      canInteract={!!props.onClick}
    >
      <DivInnerContainer>
        <DivPhoneBackground
          animate={props.phone.animate}
          style={
            props.phone.animate
              ? { rotateY: rotateScreenBg, translateZ: "-1000px" }
              : undefined
          }
          trimColor={props.phone.trimColor}
        />
        <ImgScreen
          style={
            props.phone.animate
              ? {
                  rotateY: rotateScreen,
                  translateX: translateScreenBg,
                }
              : undefined
          }
          src={props.phone.src}
          alt={props.phone.title}
          title={props.phone.title}
        />
      </DivInnerContainer>
    </DivContainer>
  );
};

const DivContainer = styled(motion.div)<{ canInteract: boolean }>`
  height: 425px;
  width: 207px;
  position: relative;
  display: block;
  perspective: 1080px;
  user-select: none;
  cursor: ${(props) => (props.canInteract ? "pointer" : undefined)};

  &:before {
    content: "";
    position: absolute;
    top: 10%;
    left: 15%;
    width: 100px;
    height: 80%;
    box-shadow: 0 0 60px black;
  }
`;

const DivInnerContainer = styled.div`
  height: 100%;
`;

const ImgScreen = styled(motion.img)`
  height: 100%;
  position: relative;
  display: block;
`;

const DivPhoneBackground = styled(motion.div)<{
  animate: boolean;
  trimColor: string;
}>`
  display: ${(props) => (props.animate ? undefined : "none")};
  height: 100%;
  width: 95%;
  border-radius: 30px;
  background-color: ${(props) => props.trimColor};
  position: absolute;
  top: 0;
  left: 5px;
`;

export default PhoneMockup;
