import React from "react";
import Waypoint from "./Waypoint";
import { Col, Row } from "react-grid-system";
import ServiceCard from "./ServiceCard";
import { Content } from "../content/content";
import MotionSlideConnectorRight from "./MotionSlideConnectorRight";
import MotionSlideConnectorDown from "./MotionSlideConnectorDown";
import ConnectorCircle from "./ConnectorCircle";
import MotionSlideConnectorUp from "./MotionSlideConnectorUp";
import MotionGrowConnectorUp from "./MotionGrowConnectorUp";
import { useBreakpoints } from "../hooks/useBreakpoints";

const BackendServices: React.FC = (): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const { isLg } = useBreakpoints();

  return (
    <Waypoint onEnter={() => setVisible(true)} threshold={0.6}>
      <Row>
        <Col lg={6}>
          <ServiceCard service={Content.services.backend} />
        </Col>
        {isLg && (
          <Col>
            <div className={"h-50p w-20p mt-25"}>
              <div className={"flex align-end h-100p"}>
                <MotionSlideConnectorRight
                  className={"bg-tertiary h-3 w-100p"}
                  transition={{ duration: 0.5 }}
                  visible={visible}
                />
              </div>
            </div>
            <div className={"h-50p w-20p"}>
              <div className={"flex justify-end h-100p overflow-hidden"}>
                <MotionSlideConnectorDown
                  className={"bg-tertiary h-100p w-3"}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  visible={visible}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={6}>
          <ServiceCard service={Content.services.data} />
        </Col>
        {isLg && (
          <Col>
            <div className={"flex h-100p w-middle-iceberg align-center mt-25"}>
              <div className={"flex h-100p w-100p align-center"}>
                <MotionSlideConnectorRight
                  className={"bg-tertiary h-3 w-100p"}
                  transition={{ duration: 1.5, delay: 1.3 }}
                  visible={visible}
                />
              </div>
              <MotionGrowConnectorUp
                className={"connect-cross-top"}
                transition={{ duration: 0.5, delay: 0.9 }}
                visible={visible}
              />
              <MotionGrowConnectorUp
                className={"connect-cross-bottom"}
                transition={{ duration: 0.5, delay: 0.9 }}
                visible={visible}
              />
              <ConnectorCircle transition={{ delay: 2.45 }} visible={visible} />
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={6}>
          <ServiceCard service={Content.services.cloud} />
        </Col>
        {isLg && (
          <Col>
            <div className={"h-50p w-20p  mt-25"}>
              <div className={"flex justify-end h-100p overflow-hidden"}>
                <MotionSlideConnectorUp
                  className={"bg-tertiary h-100p w-3"}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  visible={visible}
                />
              </div>
            </div>
            <div className={"h-50p w-20p"}>
              <div className={"flex align-start h-100p"}>
                <MotionSlideConnectorRight
                  className={"bg-tertiary h-3 w-100p"}
                  transition={{ duration: 0.5 }}
                  visible={visible}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Waypoint>
  );
};

export default BackendServices;
