import styled from "styled-components/macro";

type Justify = "flex-start" | "start" | "flex-end" | "end" | "center";
type Align = "flex-start" | "start" | "flex-end" | "end" | "center";

const DivFlex = styled.div<{ align?: Align; justify?: Justify }>`
  display: flex;
  ${props => (props.align ? `align-items: ${props.align}` : undefined)};
  ${props => (props.justify ? `justify-content: ${props.justify}` : undefined)};
`;

export default DivFlex;
