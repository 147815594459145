import { getElRect } from "./getElRect.fn";
import { getElStartYPos } from "./getElStartYPos.fn";

export function getElEndYPos(element: HTMLDivElement): number {
  const rect = getElRect(element);
  if (element.id === "test") {
    console.log(getElStartYPos(element), rect.height);
  }
  return getElStartYPos(element) + rect.height + window.innerHeight;
}
