import React, { useContext } from "react";
import { Col, Container, Row } from "react-grid-system";
import DivAbstractLandscape from "../components/DivAbstractLandscape";
import AboutMeSection from "./sections/AboutMeSection";
import DivSection from "../components/DivSection";
import ServicesSection from "./sections/ServicesSection";
import ContactSection from "./sections/ContactSection";
import BonusSection from "./sections/BonusSection";
import IntroAnimation from "../components/LandingScreenAnimation";
import FeaturedWorkSection from "./sections/FeaturedWorkSection";
import { GlobalContext } from "../components/GlobalContextWrapper";
import Waypoint from "../components/Waypoint";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { contentBasePath } from "../constants";
import MainLayout from "../layouts/MainLayout";

interface Props {}

const HomePage: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx = useContext(GlobalContext);
  const breakpoints = useBreakpoints();

  return (
    <MainLayout>
      <Container fluid>
        <Row>
          <Col>
            <Waypoint
              onChange={(isVisible: boolean) => {
                globalCtx.setShowTint(() => {
                  return !isVisible;
                });
              }}
              threshold={0.1}
            >
              <div ref={globalCtx.pageRefs.top}>
                <DivAbstractLandscape
                  viewHeight={100}
                  bgPos={"bottom"}
                  imgSrc={`${contentBasePath}/images/abstract-landscape3.jpg`}
                >
                  <IntroAnimation />
                </DivAbstractLandscape>
              </div>
            </Waypoint>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <DivSection ref={globalCtx.pageRefs.aboutMe}>
          <Row>
            <Col>
              <AboutMeSection />
            </Col>
          </Row>
        </DivSection>
      </Container>
      <Container>
        <DivSection ref={globalCtx.pageRefs.services}>
          <Row justify={"center"}>
            <Col xxl={10}>
              <ServicesSection />
            </Col>
          </Row>
        </DivSection>
      </Container>
      <Container fluid>
        <DivSection ref={globalCtx.pageRefs.work}>
          <Row>
            <Col>
              <FeaturedWorkSection />
            </Col>
          </Row>
        </DivSection>
      </Container>
      {/*<Container>*/}
      {/*  <DivSection ref={globalCtx.pageRefs.experience}>*/}
      {/*    <Row justify={"center"}>*/}
      {/*      <Col xs={12} xxl={10}>*/}
      {/*        <JourneySection />*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </DivSection>*/}
      {/*</Container>*/}
      <Container>
        <DivSection ref={globalCtx.pageRefs.contact}>
          <Row justify={"center"} align={"center"}>
            <Col xs={12} xl={10} xxl={8}>
              <ContactSection />
            </Col>
          </Row>
        </DivSection>
      </Container>
      {breakpoints.isLg && (
        <Container>
          <DivSection>
            <Row justify={"center"}>
              <Col xs={12} xl={10} xxl={8}>
                <BonusSection />
              </Col>
            </Row>
          </DivSection>
        </Container>
      )}
    </MainLayout>
  );
};

export default HomePage;
