import { motion } from "framer-motion";
import React from "react";
import { CustomTransition } from "../models/CustomTransition";

interface Props {
  visible: boolean;
  className?: string;
  transition?: CustomTransition;
}

const MotionSlideConnectorRight: React.FC<Props> = (
  props: Props
): JSX.Element => {
  const slideConnectorRight = {
    hidden: {
      width: "0%",
    },
    visible: {
      width: "100%",
      transition: {
        type: "tween",
        ease: [0, 0, 0, 1],
        delay: props.transition ? props.transition.delay : undefined,
        duration: props.transition ? props.transition.duration : undefined,
      },
    },
  };

  return (
    <motion.div
      className={props.className}
      variants={slideConnectorRight}
      initial={"hidden"}
      animate={props.visible ? "visible" : "hidden"}
    />
  );
};

export default MotionSlideConnectorRight;
