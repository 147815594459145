import styled from "styled-components/macro";
import { motion } from "framer-motion";
import Palette from "../palette";
import React from "react";
import { CustomTransition } from "../models/CustomTransition";

interface Props {
  transition: CustomTransition;
  visible: boolean;
}

const ConnectorCircle: React.FC<Props> = (props: Props): JSX.Element => {
  const springGrow = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        type: "spring",
        delay: props.transition ? props.transition.delay : undefined,
        duration: props.transition ? props.transition.duration : undefined,
      },
    },
  };

  return (
    <DivConnectorCircle
      variants={springGrow}
      initial={"hidden"}
      animate={props.visible ? "visible" : "hidden"}
    />
  );
};

const DivConnectorCircle = styled(motion.div)`
  position: relative;
  left: -7px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${Palette.tertiary};
`;

export default ConnectorCircle;
