import React from "react";
import DivTextAlign from "../../components/DivTextAlign";
import Palette from "../../palette";
import DivMargin from "../../components/DivMargin";
import SpanRobotoFont from "../../components/SpanRobotoFont";
import PageDivide from "../../components/PageDivide";
import CentredUnderlinedText from "../../components/CentredUnderlinedText";
import ContactForm from "../../components/ContactForm";
import EmailLink from "../../components/EmailLink";
import FadeInText from "../../components/FadeInText";
import FadeIn from "../../components/FadeIn";
import { useBreakpoints } from "../../hooks/useBreakpoints";

interface Props {}

const ContactSection: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  return (
    <>
      <DivTextAlign align={"center"}>
        <CentredUnderlinedText>
          <SpanRobotoFont
            color={Palette.white}
            size={breakpoints.isSm ? "90px" : "48px"}
            weight={"bold"}
          >
            Let's Talk
          </SpanRobotoFont>
        </CentredUnderlinedText>
        <DivMargin top={breakpoints.isSm ? 100 : 50} bottom={20}>
          <SpanRobotoFont
            color={Palette.white}
            size={breakpoints.isSm ? "35px" : "22px"}
          >
            <FadeInText>Send me an email</FadeInText>
          </SpanRobotoFont>
        </DivMargin>
        <FadeIn up distance={15}>
          <EmailLink
            fontSize={breakpoints.isSm ? 50 : 30}
            highlightThickness={4}
          />
        </FadeIn>
      </DivTextAlign>
      <DivMargin
        top={breakpoints.isSm ? 150 : 75}
        bottom={breakpoints.isSm ? 125 : 62}
      >
        <PageDivide>OR</PageDivide>
      </DivMargin>
      <DivTextAlign align={"center"}>
        <SpanRobotoFont
          size={breakpoints.isSm ? "30px" : "25px"}
          color={Palette.white}
        >
          <FadeInText>Tell me about your project</FadeInText>
        </SpanRobotoFont>
      </DivTextAlign>
      <DivMargin>
        <DivMargin top={50}>
          <ContactForm />
        </DivMargin>
      </DivMargin>
    </>
  );
};

export default ContactSection;
