import React, { createContext, useRef } from "react";
import { GlobalContextModel } from "../models/globalContext.model";

interface Props {
  children: any;
}

export const GlobalContext = createContext<GlobalContextModel>(
  {} as GlobalContextModel
);

const GlobalContextWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const [showTint, setShowTint] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [pageRefs, setPageRefs] = React.useState({
    top: useRef<HTMLDivElement>(null),
    aboutMe: useRef<HTMLDivElement>(null),
    services: useRef<HTMLDivElement>(null),
    work: useRef<HTMLDivElement>(null),
    experience: useRef<HTMLDivElement>(null),
    contact: useRef<HTMLDivElement>(null),
  });

  return (
    <GlobalContext.Provider
      value={{
        showTint,
        setShowTint,
        isMenuOpen,
        setIsMenuOpen,
        isLoading,
        setIsLoading,
        pageRefs,
        setPageRefs,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextWrapper;
