import React, { createContext } from "react";
import { WorkItemModel } from "../models/workItem.model";

const WorkItemContext = createContext<WorkItemModel>({} as WorkItemModel);

const WorkItemContextWrapper: React.FC<{
  workItem: WorkItemModel;
  children?: any;
}> = (props): JSX.Element => {
  return (
    <WorkItemContext.Provider value={props.workItem}>
      {props.children}
    </WorkItemContext.Provider>
  );
};

export default WorkItemContextWrapper;
