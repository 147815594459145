import React, { useRef } from "react";
import DivMargin from "../../components/DivMargin";
import DivFlex from "../../components/DivFlex";
import { Col, Row } from "react-grid-system";
import SpanRobotoFont from "../../components/SpanRobotoFont";
import Palette from "../../palette";
import HeadingOverlined from "../../components/HeadingOverlined";
import styled from "styled-components/macro";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { useRefScrollProgress } from "../../hooks/useRefScrollProgress";
import Icon from "../../components/Icon";
import { AiOutlineLinkedin, AiOutlineGithub } from "react-icons/ai";
import Waypoint from "../../components/Waypoint";
import { contentBasePath, githubUrl, linkedInUrl } from "../../constants";
import { BreakpointsModel, useBreakpoints } from "../../hooks/useBreakpoints";
import SpecialText from "../../components/SpecialText";
import { SiTypescript, SiJavascript } from "react-icons/si";
import Anchor from "../../components/Anchor";
import FadeIn from "../../components/FadeIn";

interface Props {}

const AboutMeSection: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();
  const [visible, setVisible] = React.useState(false);
  const imgRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useViewportScroll();
  const { start, end } = useRefScrollProgress(imgRef);
  const meAnimate = useTransform(scrollY, [start, end], ["0px", "-50px"]);
  const treeAnimate = useTransform(
    scrollY,
    [start - 200, start, end - 45],
    ["-150px", "-275px", "-30px"]
  );
  const bgAnimate = useTransform(scrollY, [start, end], ["-50px", "0px"]);

  const slideIn = {
    hidden: {
      translateX: breakpoints.isSm ? "-7.5%" : "0%",
    },
    visible: {
      translateX: "0%",
      transition: {
        duration: 0,
      },
    },
  };

  return (
    <Waypoint onEnter={() => setVisible(true)}>
      <Row>
        <Col xs={12} lg={12} xl={5.5}>
          <DivMargin bottom={breakpoints.isXl ? 0 : 50}>
            <DivImgContainer
              ref={imgRef}
              variants={slideIn}
              initial={"hidden"}
              animate={visible ? "visible" : "hidden"}
              breakpoints={breakpoints}
            >
              <DivImageScaler>
                <DivImgProfileBg
                  style={{
                    backgroundPositionX: breakpoints.isSm
                      ? bgAnimate
                      : "center",
                  }}
                  breakpoints={breakpoints}
                />
                <DivImgProfileShot
                  style={{
                    backgroundPositionX: breakpoints.isSm
                      ? meAnimate
                      : "center",
                  }}
                  breakpoints={breakpoints}
                />
                {breakpoints.isSm && (
                  <DivImgProfileFg
                    style={{ backgroundPositionX: treeAnimate }}
                    breakpoints={breakpoints}
                  />
                )}
              </DivImageScaler>
            </DivImgContainer>
          </DivMargin>
        </Col>
        <Col xs={12} xl={6.5}>
          <DivMargin
            right={!breakpoints.isSm ? 10 : 50}
            left={!breakpoints.isSm ? 10 : 25}
          >
            <Row>
              <Col>
                <HeadingOverlined fontSize={!breakpoints.isSm ? 45 : 65}>
                  Me
                </HeadingOverlined>
              </Col>
            </Row>
            <Row>
              <Col>
                <FadeIn up distance={20}>
                  <SpanRobotoFont
                    color={Palette.white}
                    size={breakpoints.isSm ? "20px" : "15px"}
                  >
                    Tyler is a full stack Software Engineer who is passionate
                    about helping organisations achieve their business goals
                    through digital solutions. He works out of Sydney, Australia
                    but does freelance and consulting work for companies around
                    the world. His languages of choice are&nbsp;
                    <SpecialText
                      icon={SiTypescript}
                      iconColor={"#3a8ce3"}
                      href={"https://www.typescriptlang.org/"}
                    >
                      Typescript
                    </SpecialText>
                    &nbsp;and&nbsp;
                    <SpecialText
                      icon={SiJavascript}
                      iconColor={"#ff8d1f"}
                      href={"https://www.javascript.com/"}
                    >
                      Javascript
                    </SpecialText>
                    . He specialises in building scalable and resilient cloud
                    based solutions using the latest technologies and
                    architectural patterns such as serverless computing,
                    micro-services and containerised apps. His engineering
                    philosophy revolves around the&nbsp;
                    <Anchor
                      color={Palette.secondaryLight}
                      hoverColor={Palette.secondaryDark}
                      href={
                        "https://aws.amazon.com/blogs/apn/the-6-pillars-of-the-aws-well-architected-framework/"
                      }
                      target={"_blank"}
                    >
                      six pillars
                    </Anchor>
                    &nbsp;of architecture design: security, reliability,
                    performance, cost-optimisation and operational excellence.
                    On a personal level, He is an avid football fan (the kind
                    with the goalkeepers and nets, go Sydney FC!), video gamer,
                    dog lover, gym go-er and overall good guy (perhaps he's
                    biased in that view). He is an honest and hard worker, he
                    likes to do things the right way, not the easy way, and he
                    enjoys contributing to positive and high-performing teams.
                  </SpanRobotoFont>
                </FadeIn>
              </Col>
            </Row>
            <Row>
              <Col>
                <DivMargin top={30} bottom={30}>
                  <DivIconContainer justify={"flex-start"} align={"center"}>
                    <Icon
                      title={"View my LinkedIn profile"}
                      icon={AiOutlineLinkedin}
                      color={Palette.tertiary}
                      hoverColor={Palette.secondary}
                      size={40}
                      href={linkedInUrl}
                      target={"_blank"}
                    />
                    <Icon
                      title={"View my Github profile"}
                      icon={AiOutlineGithub}
                      color={Palette.tertiary}
                      hoverColor={Palette.secondary}
                      size={40}
                      href={githubUrl}
                      target={"_blank"}
                    />
                  </DivIconContainer>
                </DivMargin>
              </Col>
            </Row>
          </DivMargin>
        </Col>
      </Row>
    </Waypoint>
  );
};

const DivIconContainer = styled(DivFlex)`
  .react-icon {
    margin-right: 20px;
  }
`;

const DivImgContainer = styled(motion.div)<{ breakpoints: BreakpointsModel }>`
  height: 500px;
  border-top-right-radius: ${(props) => (!props.breakpoints.isSm ? 0 : 20)}px;
  border-bottom-right-radius: ${(props) =>
    !props.breakpoints.isSm ? 0 : 20}px;
  box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  filter: ${(props) => (props.breakpoints.isXl ? "grayscale()" : undefined)};
  transition: filter 750ms ease-out,
    transform 2s cubic-bezier(0.23, 0, 0.075, 1.005);

  &:hover {
    filter: none;
  }
`;

const DivImageScaler = styled(motion.div)`
  height: 105%;
  width: 105%;

  @media only screen and (min-width: 1200px) {
    transform: scale(1);
    transition: transform 750ms ease-out;

    &:hover {
      transition: transform 500ms ease-in;
      transform: scale(1.015);
    }
  }
`;

const DivImgProfile = styled(motion.div)<{ breakpoints: BreakpointsModel }>`
  border-top-right-radius: ${(props) => (props.breakpoints.isSm ? 20 : 0)}px;
  border-bottom-right-radius: ${(props) => (props.breakpoints.isSm ? 20 : 0)}px;
  background-repeat: no-repeat;
  background-position-y: top;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const DivImgProfileBg = styled(DivImgProfile)<{
  breakpoints: BreakpointsModel;
}>`
  border-top-right-radius: ${(props) => (props.breakpoints.isSm ? 20 : 0)}px;
  border-bottom-right-radius: ${(props) => (props.breakpoints.isSm ? 20 : 0)}px;
  background-image: url("${`${contentBasePath}/images/profile-bg.jpg`}");
  background-size: cover;
  background-position-y: top;
  z-index: 1;
`;

const DivImgProfileShot = styled(DivImgProfile)<{
  breakpoints: BreakpointsModel;
}>`
  border-top-right-radius: ${(props) => (props.breakpoints.isSm ? 20 : 0)}px;
  border-bottom-right-radius: ${(props) => (props.breakpoints.isSm ? 20 : 0)}px;
  background-image: url("${`${contentBasePath}/images/profile-fg.png`}");
  background-size: cover;
  background-position-y: top;
  z-index: 2;
`;

const DivImgProfileFg = styled(DivImgProfile)`
  border-top-right-radius: ${(props) => (props.breakpoints.isSm ? 20 : 0)}px;
  border-bottom-right-radius: ${(props) => (props.breakpoints.isSm ? 20 : 0)}px;
  background-size: cover;
  background-image: url("${`${contentBasePath}/images/profile-tree.png`}");
  z-index: 3;
`;

export default AboutMeSection;
