import styled from "styled-components/macro";
import Palette from "../palette";
import Fonts from "../fonts";
import React from "react";
import { IconType } from "react-icons";
import Icon from "./Icon";
import DivFlex from "./DivFlex";
import ButtonLoader from "./ButtonLoader";

interface Props {
  children: string;
  icon?: IconType;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  type?: "submit" | "reset" | "button";
}

const Button: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <ButtonStyled
      className={props.className}
      type={props.type}
      onClick={props.onClick}
    >
      <DivFlex justify={"center"} align={"center"}>
        {props.icon && (
          <Icon title={""} icon={props.icon} color={Palette.white} size={15} />
        )}
        <SpanText>{props.children}</SpanText>
        {props.loading && <ButtonLoader />}
      </DivFlex>
    </ButtonStyled>
  );
};
const SpanText = styled.span`
  margin-left: 10px;
  position: relative;
  display: block;
`;

const ButtonStyled = styled.button`
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${Palette.tertiary};
    border-radius: 30px;
    transition: 350ms ease-in-out;
  }

  user-select: none;
  color: ${Palette.white};
  will-change: auto;
  background-color: transparent;
  position: relative;
  padding: 0 35px;
  line-height: 3em;
  border: 0;
  outline: 0;
  display: inline-block;
  font-family: ${Fonts.roboto};
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;

  &:hover {
    ::before {
      transition: 100ms ease-in-out;
      transform: scaleX(1.07);
      background-color: ${Palette.secondary};
      box-shadow: 0 0 12px ${Palette.secondary};
    }
  }
`;

export default Button;
