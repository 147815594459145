import React from "react";
import styled from "styled-components/macro";
import Palette from "../palette";
import Fonts from "../fonts";
import { motion } from "framer-motion";
import Waypoint from "./Waypoint";
import DivFlex from "./DivFlex";
import { useBreakpoints } from "../hooks/useBreakpoints";

interface Props {
  children: any;
  fontSize?: number;
}

const HeadingOverlined: React.FC<Props> = (props: Props): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const breakpoints = useBreakpoints();

  const growMiddle = {
    hidden: {
      width: 0,
      transition: {
        delay: 0,
        duration: 0,
      },
    },
    visible: {
      width: "100%",
      transition: {
        delay: 0.1,
        duration: 0.2,
      },
    },
  };

  return (
    <Waypoint onEnter={() => setVisible(true)}>
      <DivContainer fontSize={props.fontSize}>
        <DivOverlineContainer
          justify={"center"}
          align={"center"}
          isSm={breakpoints.isSm}
        >
          <DivOverline
            variants={growMiddle}
            initial={"hidden"}
            animate={visible ? "visible" : "hidden"}
            exit={"hidden"}
          />
        </DivOverlineContainer>
        {props.children}
      </DivContainer>
    </Waypoint>
  );
};

const DivOverlineContainer = styled(DivFlex)<{ isSm: boolean }>`
  width: ${(props) => (!props.isSm ? 50 : 75)}px;
`;

const DivOverline = styled(motion.div)`
  height: 4px;
  background-color: ${Palette.secondary};
  margin-bottom: 15px;
`;

const DivContainer = styled.div<{ fontSize: number | undefined }>`
  display: inline-block;
  padding-bottom: 20px;
  color: ${Palette.white};
  font-family: ${Fonts.playfair};
  font-weight: 700;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 75)}px;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  line-height: 1em;
`;

export default HeadingOverlined;
