import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components/macro";
import { getRandNumBetween } from "../functions/getRandNumBetween.fn";
import Waypoint from "./Waypoint";

interface Props {
  chunkSizeInChars?: number;
  children: any;
}

const FadeInText: React.FC<Props> = (props: Props): JSX.Element => {
  const [visible, setVisible] = React.useState(false);

  const staggerFadeIn = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0,
        duration: 0,
        when: "beforeChildren",
        staggerChildren: 0.03,
      },
    },
  };

  const fadeIn = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  const getTextChunkArray = (chunkSizeInChars: number): string[] => {
    const chunkArray: string[] = [];
    let caretPos = 0;
    do {
      chunkArray.push(
        props.children.substring(caretPos, caretPos + chunkSizeInChars)
      );
      caretPos += chunkSizeInChars;
    } while (caretPos <= props.children.length);
    return chunkArray;
  };

  return (
    <Waypoint onEnter={() => setVisible(true)}>
      <SpanContainer
        initial={"hidden"}
        animate={visible ? "visible" : "hidden"}
        exit={"hidden"}
        variants={staggerFadeIn}
      >
        {getTextChunkArray(getRandNumBetween(30, 80)).map((chunk, index) => (
          <motion.span key={index} variants={fadeIn}>
            {chunk}
          </motion.span>
        ))}
      </SpanContainer>
    </Waypoint>
  );
};

const SpanContainer = styled(motion.span)`
  height: 100%;
`;

export default FadeInText;
