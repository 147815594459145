import React from "react";
import { WorkListItemModel } from "../models/workItem.model";
import { contentBasePath, csaaCertUrl } from "../constants";
import Palette from "../palette";
import Anchor from "../components/Anchor";

const services = {
  frontend: {
    name: "Frontend",
    description: (
      <>
        Tyler is a skilled frontend web developer with a strong background in
        React using Typescript. He has experience in creating responsive and
        themable web applications and creating reusable components that meet
        WCAG 2.0 compliance standards. Has a proven history of proficiency in
        converting UX/UI designs into responsive and user-friendly web
        interfaces.
      </>
    ),
  },
  backend: {
    name: "Backend",
    description: (
      <>
        Having worked extensively with Node.js in API development using
        serverless technologies and containerised applications, Tyler possesses
        a deep understanding of how to create performant, secure, scalable and
        resilient applications. He is proficient in implementing CI/CD pipelines
        and has experience in converting monolithic backend systems into
        serverless architecture.
      </>
    ),
  },
  data: {
    name: "Database",
    description: (
      <>
        Tyler has experience working with both SQL and NoSQL databases. He is
        familiar with designing and implementing database schemas, writing
        efficient and performant queries, and optimizing data access patterns.
        He has experience working with SQL databases such as MySQL, PostgreSQL
        and OracleDB. He has worked extensively with NoSQL databases like
        DynamoDB and is familiar with their unique data modeling and querying
        capabilities.
      </>
    ),
  },
  cloud: {
    name: "Cloud",
    description: (
      <>
        Having achieved certification as an{" "}
        <Anchor
          href={csaaCertUrl}
          color={Palette.secondaryLight}
          hoverColor={Palette.secondaryDark}
          target={"_blank"}
          referrerPolicy={"no-referrer"}
        >
          AWS Certified Solutions Architect
        </Anchor>
        , Tyler is an expert in the AWS Cloud. He has extensive experience using
        a variety of AWS services spanning Compute, Storage, Data and Web. He
        has experience in using infrastructure-as-code frameworks like Terraform
        to create scalable and resilient Cloud systems.
      </>
    ),
  },
};

const workItems: WorkListItemModel[] = [
  {
    imgSrc: `${contentBasePath}/images/cf-functions-browser-1.jpg`,
    name: "CF-Functions",
    techs: "CloudFront Functions / CLI / Open Source",
    description:
      "A framework designed to take the headache out of deploying and maintaining AWS CloudFront Functions.",
    year: 2023,
    href: "https://burketyler.github.io/cf-functions/",
  },
  {
    imgSrc: `${contentBasePath}/images/zenb-global-browser-1.jpg`,
    name: "ZENB & FC Barcelona",
    techs: "React / CodePipeline / CloudFront Functions",
    description:
      "A global landing page for the partnership between the plant based food retailer ZENB and the famous FC Barcelona.",
    year: 2022,
    href: "/#/work/zenb",
  },
  {
    imgSrc: `${contentBasePath}/images/nadohe-browser-1.jpg`,
    name: "NADOHE.org",
    techs: "React / GatsbyJS / Netlify CMS / GitHub Actions",
    description:
      "Site re-fresh and re-brand for the National Association of Diversity " +
      "Officers in Higher Education organisation.",
    year: 2022,
    href: "/#/work/nadohe",
  },
  {
    imgSrc: `${contentBasePath}/images/bigsby-browser-1.jpg`,
    name: "BigsbyJS",
    techs: "Lambda / Docusaurus / Open Source",
    description:
      "Think Middy meets NestJS. A richly featured library for writing easy, " +
      "powerful lambda functions.",
    year: 2022,
    href: "https://burketyler.github.io/bigsby/",
  },
  {
    imgSrc: `${contentBasePath}/images/talksig-browser-3.jpg`,
    name: "Talking Significance",
    techs: "React / Lambda / DynamoDB / Cognito",
    description:
      "A digital platform crated for a private financial services company, " +
      "enabling financial advisors to help their clients determine whats most " +
      "important for their financial future.",
    year: 2021,
    href: "/#/work/talking-significance",
  },
  {
    imgSrc: `${contentBasePath}/images/eso-browser-1.jpg`,
    name: "Elder Scrolls LFG",
    techs: "React / Lambda / DynamoDB / ECS / Fastify",
    description:
      "A real-time activity finder service made for the Elder Scrolls Online MMO.",
    year: 2020,
    href: "/#/work/esolfg",
  },
  {
    imgSrc: `${contentBasePath}/images/mlc-browser-1.jpg`,
    name: "MLC - Navigator/Wrap",
    techs: "React / Lambda / Oracle DB / Spring Boot",
    description:
      "A secure investor portal where MLC Navigator/Wrap users can log in and view account information.",
    year: 2019,
    href: "/#/work/mlc",
  },
  {
    imgSrc: `${contentBasePath}/images/kebc-browser-1.jpg`,
    name: "King's Empire BattleCalc",
    techs: "iOS / Xamarin / .NET / C# / XCode",
    description: "A calculation tool for the popular iOS MMO King’s Empire.",
    year: 2014,
    href: "/#/work/kebc",
  },
];

export const Content = {
  services,
  workItems,
};
