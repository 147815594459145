import React from "react";
import styled from "styled-components/macro";
import Palette from "../palette";
import Fonts from "../fonts";
import DivFlex from "./DivFlex";
import DivMargin from "./DivMargin";
import { motion } from "framer-motion";
import Waypoint from "./Waypoint";

interface Props {
  children: any;
}

const CentredUnderlinedText: React.FC<Props> = (props: Props): JSX.Element => {
  const [visible, setVisible] = React.useState(false);

  const growMiddle = {
    hidden: {
      width: 0,
      transition: {
        delay: 0,
        duration: 0,
      },
    },
    visible: {
      width: "40%",
      transition: {
        delay: 0.1,
        duration: 0.2,
      },
    },
  };

  return (
    <Waypoint onEnter={() => setVisible(true)}>
      <DivFlex justify={"center"} align={"center"}>
        <DivContainer>
          <DivMargin bottom={10}>
            <h1>{props.children}</h1>
          </DivMargin>
          <DivFlex justify={"center"} align={"center"}>
            <DivUnderline
              variants={growMiddle}
              initial={"hidden"}
              animate={visible ? "visible" : "hidden"}
            />
          </DivFlex>
        </DivContainer>
      </DivFlex>
    </Waypoint>
  );
};

const DivContainer = styled.div`
  color: ${Palette.white};
  font-family: ${Fonts.roboto};
  font-weight: 300;
  font-size: 35px;
  -webkit-font-smoothing: antialiased;
`;

const DivUnderline = styled(motion.div)`
  height: 5px;
  background-color: ${Palette.secondary};
  margin-top: 10px;
`;

export default CentredUnderlinedText;
