import { FontWeightModel } from "../models/fontWeight.model";

export function calcWeight(weight: FontWeightModel): number {
  switch (weight) {
    case "thin":
      return 100;
    case "light":
      return 300;
    case "regular":
      return 400;
    case "bold":
      return 700;
    default:
      return 300;
  }
}
