import React, { useContext } from "react";
import DivCard from "./DivCard";
import { Grid, TextField } from "@material-ui/core";
import Button from "./Button";
import DivFlex from "./DivFlex";
import {
  ContactFormErrorsModel,
  ContactFormModel,
} from "../models/contactForm.model";
import { validateContact } from "../functions/validateContactForm.fn";
import SuccessIcon from "./SuccessIcon";
import DivMargin from "./DivMargin";
import Palette from "../palette";
import SpanRobotoFont from "./SpanRobotoFont";
import { motion, useAnimation } from "framer-motion";
import styled from "styled-components/macro";
import { GlobalContext } from "./GlobalContextWrapper";
import * as Sentry from "@sentry/react";

const ContactForm: React.FC = (): JSX.Element => {
  const [form, setForm] = React.useState({} as ContactFormModel);
  const [errors, setErrors] = React.useState({} as ContactFormErrorsModel);
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const animateForm = useAnimation();
  const globalCtx = useContext(GlobalContext);

  const handleError = (err: Error) => {
    setLoading(false);
    globalCtx.setIsLoading(false);
    Sentry.captureEvent(err);
    setErrors((prevState) => {
      return {
        ...prevState,
        form:
          "Something went wrong and your submission was not successful. I'm probably already looking in to this, please email me instead in the meantime.",
      };
    });
  };

  const handleSuccess = () => {
    setLoading(false);
    globalCtx.setIsLoading(false);
    animateForm
      .start({
        opacity: 0,
        translateX: "900px",
        transition: {
          duration: 0.3,
        },
      })
      .then(() => {
        setSubmitted(true);
      });
  };

  const callApi = (request: ContactFormModel): Promise<void> => {
    setLoading(true);
    globalCtx.setIsLoading(true);
    return fetch("https://api.tylerburke.dev/contact/submit", {
      method: "POST",
      body: JSON.stringify({
        ...request,
        budget: !!request.budget ? Number(request.budget) : undefined,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          handleError(
            new Error(`Contact API returned statusCode: ${response.status}.`)
          );
        } else {
          handleSuccess();
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const submitForm = async (event: any): Promise<void> => {
    event.preventDefault();
    if (validateContact.validateForm(form, setErrors)) {
      await callApi(form);
    }
  };

  const renderConfirmation = () => (
    <DivConfirmContainer
      initial={{ opacity: 0, translateX: "-900px" }}
      animate={{ opacity: 1, translateX: "0px" }}
      transition={{
        duration: 0.2,
      }}
    >
      <SuccessIcon />
      <DivMargin left={25}>
        <SpanRobotoFont color={Palette.white} size={"40px"}>
          Thank you!
        </SpanRobotoFont>
        <DivMargin top={10}>
          <SpanRobotoFont color={Palette.white} size={"20px"}>
            Your submission was received loud and clear.
            <br />
            I'll get back to you as soon as I can.
          </SpanRobotoFont>
        </DivMargin>
      </DivMargin>
    </DivConfirmContainer>
  );

  const renderForm = () => (
    <DivCard
      initial={{ scale: 1, translateY: "0px", translateX: "0px" }}
      animate={animateForm}
    >
      <form onSubmit={submitForm} noValidate>
        <Grid container direction={"column"} spacing={3}>
          <Grid container item direction={"row"} spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                error={!!errors.name}
                helperText={errors.name}
                fullWidth
                id="contact-name"
                label="Name"
                variant="filled"
                required
                onBlur={(event) => {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      name: validateContact.getErrorMessageName(
                        event.target.value
                      ),
                    };
                  });
                }}
                onChange={(event) => {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      name: undefined,
                    };
                  });
                  setForm((prevState) => {
                    return { ...prevState, name: event.target.value };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="contact-company-name"
                label="Company Name"
                variant="filled"
              />
            </Grid>
          </Grid>
          <Grid container item direction={"column"} spacing={3}>
            <Grid item>
              <TextField
                error={!!errors.email}
                helperText={errors.email}
                type={"email"}
                fullWidth
                id="contact-email"
                label="Email Address"
                variant="filled"
                required
                onBlur={(event) => {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      email: validateContact.getErrorMessageEmail(
                        event.target.value
                      ),
                    };
                  });
                }}
                onChange={(event) => {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      email: undefined,
                    };
                  });
                  setForm((prevState) => {
                    return { ...prevState, email: event.target.value };
                  });
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                error={!!errors.objectives}
                helperText={errors.objectives}
                multiline
                fullWidth
                id="contact-objectives"
                label="Objectives"
                variant="filled"
                rows={5}
                required
                onBlur={(event) => {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      objectives: validateContact.getErrorMessageObjectives(
                        event.target.value
                      ),
                    };
                  });
                }}
                onChange={(event) => {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      objectives: undefined,
                    };
                  });
                  setForm((prevState) => {
                    return { ...prevState, objectives: event.target.value };
                  });
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                error={!!errors.budget}
                helperText={errors.budget}
                fullWidth
                type={"number"}
                id="contact-budget"
                label="Budget"
                variant="filled"
                onBlur={(event) => {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      budget: validateContact.getErrorMessageBudget(
                        event.target.value
                      ),
                    };
                  });
                }}
                onChange={(event) => {
                  setErrors((prevState) => {
                    return {
                      ...prevState,
                      budget: undefined,
                    };
                  });
                  setForm((prevState) => {
                    return { ...prevState, budget: event.target.value };
                  });
                }}
              />
            </Grid>
            <Grid item>
              {errors.form && (
                <DivMargin bottom={10} top={10}>
                  <SpanRobotoFont
                    size={"15px"}
                    color={Palette.error}
                    weight={"bold"}
                  >
                    {errors.form}
                  </SpanRobotoFont>
                </DivMargin>
              )}
              <DivFlex justify={"center"}>
                <Button type={"submit"} loading={loading}>
                  Submit
                </Button>
              </DivFlex>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </DivCard>
  );

  return submitted ? renderConfirmation() : renderForm();
};

const DivConfirmContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 535px;
`;

export default ContactForm;
