import React from "react";
import styled from "styled-components/macro";
import DivFlex from "./DivFlex";
import Fonts from "../fonts";
import {
  WorkItemInfoCardModel,
  WorkItemThemeModel,
} from "../models/workItem.model";
import { BreakpointsModel, useBreakpoints } from "../hooks/useBreakpoints";

interface Props {
  card: WorkItemInfoCardModel;
  theme: WorkItemThemeModel;
}

const WorkItemInfoCard: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  return (
    <DivContainer itemtheme={props.theme}>
      <DivFlex align={"flex-end"}>
        <DivHighlight breakpoints={breakpoints} itemtheme={props.theme} />
        <DivHeading itemtheme={props.theme} breakpoints={breakpoints}>
          {props.card.heading}
        </DivHeading>
      </DivFlex>
      <DivText>{props.card.body}</DivText>
    </DivContainer>
  );
};

const DivContainer = styled.div<{ itemtheme: WorkItemThemeModel }>`
  background-color: ${(props) => props.itemtheme.cardBg};
  border: 1px solid ${(props) => props.itemtheme.cardTrim};
  padding: 20px;
`;

const DivHighlight = styled.div<{
  itemtheme: WorkItemThemeModel;
  breakpoints: BreakpointsModel;
}>`
  background-color: ${(props) => props.itemtheme.cardHighlight};
  height: 10px;
  width: 3px;
  position: relative;
  bottom: ${(props) => (props.breakpoints.isSm ? 5 : 3)}px;
  margin-right: 5px;
`;

const DivHeading = styled.div<{
  itemtheme: WorkItemThemeModel;
  breakpoints: BreakpointsModel;
}>`
  font-family: ${Fonts.roboto};
  font-weight: 700;
  font-size: ${(props) => (props.breakpoints.isSm ? 25 : 18)}px;
  color: ${(props) => props.itemtheme.heading};
`;

const DivText = styled.div`
  margin-top: 10px;
`;

export default WorkItemInfoCard;
