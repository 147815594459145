import { useMedia } from "react-use";

export interface BreakpointsModel {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  isXxl: boolean;
}

export function useBreakpoints(): BreakpointsModel {
  const isXs = useMedia("(min-width: 1px)");
  const isSm = useMedia("(min-width: 576px)");
  const isMd = useMedia("(min-width: 768px)");
  const isLg = useMedia("(min-width: 992px)");
  const isXl = useMedia("(min-width: 1200px)");
  const isXxl = useMedia("(min-width: 1600px)");

  return { isXs, isSm, isMd, isLg, isXl, isXxl };
}
