import styled from "styled-components/macro";
import Palette from "../palette";
import Fonts from "../fonts";
import React from "react";
import { IconType } from "react-icons";
import Icon from "./Icon";
import DivFlex from "./DivFlex";

interface Props {
  children: string;
  icon?: IconType;
  onClick?: () => void;
}

const NavbarButton: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <ButtonStyled onClick={props.onClick}>
      <DivFlex justify={"center"} align={"center"}>
        {props.icon && (
          <DivIcon>
            <Icon
              title={""}
              icon={props.icon}
              color={Palette.white}
              size={15}
            />
          </DivIcon>
        )}
        <SpanText>{props.children}</SpanText>
      </DivFlex>
    </ButtonStyled>
  );
};

const DivIcon = styled.div`
  margin-right: 5px;
  position: relative;
  display: block;
`;

const SpanText = styled.span`
  position: relative;
  display: block;
`;

const ButtonStyled = styled.button`
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${Palette.tertiaryLight};
    border-radius: 30px;
    transition: 500ms ease-in-out;
  }

  user-select: none;
  color: ${Palette.white};
  will-change: auto;
  background-color: transparent;
  position: relative;
  padding: 0 35px;
  line-height: 3em;
  border: 0;
  outline: 0;
  display: inline-block;
  font-family: ${Fonts.roboto};
  font-weight: 600;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;

  &:hover {
    ::before {
      transition: 100ms ease-in-out;
      background-color: ${Palette.tertiary};
    }
  }
`;

export default NavbarButton;
