import {
  ContactFormErrorsModel,
  ContactFormModel,
} from "../models/contactForm.model";
import { SetState } from "../models/setState.model";

function isAllObjectValuesEmpty(...objects: any[]): boolean {
  if (isDefined(objects) && objects.length > 0) {
    return objects.reduce((isEmpty: boolean, obj: any) => {
      if (obj) {
        return (
          Object.values(obj).filter((field) => isDefined(field) && field !== "")
            .length === 0
        );
      } else {
        return true;
      }
    }, true);
  } else {
    return false;
  }
}

function isDefined(value: any): boolean {
  return value !== undefined && value !== null && value !== "";
}

function isNumeric(value: any): boolean {
  if (!isDefined(value)) {
    return false;
  }
  return !isNaN(Number(value));
}

function isAboveZero(value: number): boolean {
  if (!isDefined(value)) {
    return false;
  }
  return value > 0;
}

function isEmail(email: string): boolean {
  if (!isDefined(email)) {
    return false;
  }
  const emailRegex = new RegExp("^.+@[^\\.].*\\.[a-z]{2,}$");
  return emailRegex.test(email);
}

function getErrorMessageName(name: string): string | undefined {
  return isDefined(name) ? undefined : "Please enter your name.";
}

function getErrorMessageEmail(email: string): string | undefined {
  return isEmail(email) ? undefined : "Please enter a valid email address.";
}

function getErrorMessageObjectives(objectives: string): string | undefined {
  return isDefined(objectives)
    ? undefined
    : "Please tell me about your objectives.";
}

function getErrorMessageBudget(budget: string): string | undefined {
  if (!isDefined(budget)) {
    return undefined;
  }
  return isNumeric(budget) && isAboveZero(Number(budget))
    ? undefined
    : "Please enter a valid budget amount.";
}

function validateForm(
  form: ContactFormModel,
  setErrors: SetState<ContactFormErrorsModel>
): boolean {
  const errors: ContactFormErrorsModel = {
    name: getErrorMessageName(form.name),
    email: getErrorMessageEmail(form.email),
    objectives: getErrorMessageObjectives(form.objectives),
    budget: isDefined(form.budget)
      ? getErrorMessageBudget(form.budget!)
      : undefined,
  };
  setErrors(errors);
  return isAllObjectValuesEmpty(errors);
}

export const validateContact = {
  validateForm,
  getErrorMessageObjectives,
  getErrorMessageEmail,
  getErrorMessageName,
  getErrorMessageBudget,
};
