import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import styled from "styled-components/macro";
import UlNoBullets from "./UlNoBullets";
import DivFlex from "./DivFlex";
import NavbarLink from "./NavbarLink";
import Logo from "./Logo";
import Palette from "../palette";
import DivMargin from "./DivMargin";
import { FaDownload, FaEnvelope } from "react-icons/fa";
import NavbarButton from "./NavbarButton";
import { motion, useViewportScroll } from "framer-motion";
import { GlobalContext } from "./GlobalContextWrapper";
import { useHistory } from "react-router-dom";
import { goToAnchor } from "../functions/goToAnchor.fn";
import { contentBasePath, myEmail } from "../constants";
import DivPageContainer from "./DivPageContainer";

interface Props {
  bgColor: string;
  highlightColor: string;
  hideButtons?: boolean;
  noFloat?: boolean;
}

const Navbar: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx = useContext(GlobalContext);
  const [hide, setHide] = React.useState(false);
  const { scrollY } = useViewportScroll();
  const history = useHistory();

  const variants = {
    hidden: {
      opacity: 0,
      translateY: "-75px",
      transition: {
        duration: 0.5,
      },
    },
    visible: {
      opacity: 1,
      translateY: "0px",
      transition: {
        duration: 0.3,
      },
    },
  };

  useEffect(() => {
    if (!props.noFloat) {
      const unsub = scrollY.onChange((yVal) => {
        if (yVal >= 45 && !hide) {
          setHide(true);
        } else if (yVal < 45 && hide) {
          setHide(false);
        }
      });
      return () => unsub();
    }
  });

  return (
    <DivContainer
      variants={variants}
      initial={"visible"}
      animate={hide ? "hidden" : "visible"}
      {...props}
    >
      <Row>
        <Col>
          <DivPageContainer>
            <Row>
              <Col lg={1.25}>
                <DivSection justify={"center"}>
                  <Logo color={Palette.white} size={35} href={"/"} />
                </DivSection>
              </Col>
              <Col lg={6.75}>
                <DivSection>
                  <UlNavbarLinks>
                    <NavbarLink
                      href={"/#"}
                      title={"Home"}
                      highlightColor={props.highlightColor}
                    />
                    <NavbarLink
                      onClick={() =>
                        goToAnchor(history, "/", globalCtx.pageRefs.aboutMe)
                      }
                      title={"Who"}
                      highlightColor={props.highlightColor}
                    />
                    <NavbarLink
                      onClick={() =>
                        goToAnchor(history, "/", globalCtx.pageRefs.services)
                      }
                      title={"What"}
                      highlightColor={props.highlightColor}
                    />
                    <NavbarLink
                      href={"/#/work"}
                      title={"Work"}
                      highlightColor={props.highlightColor}
                    />
                    {/*<NavbarLink*/}
                    {/*  onClick={() =>*/}
                    {/*    goToAnchor(history, "/", globalCtx.pageRefs.experience)*/}
                    {/*  }*/}
                    {/*  title={"Experience"}*/}
                    {/*  highlightColor={props.highlightColor}*/}
                    {/*/>*/}
                    <NavbarLink
                      onClick={() =>
                        goToAnchor(history, "/", globalCtx.pageRefs.contact)
                      }
                      title={"Contact"}
                      highlightColor={props.highlightColor}
                    />
                  </UlNavbarLinks>
                </DivSection>
              </Col>
              {!props.hideButtons && (
                <Col lg={4}>
                  <DivSection justify={"flex-end"}>
                    <DivMargin right={15}>
                      <NavbarButton
                        icon={FaDownload}
                        onClick={() =>
                          window.open(`${contentBasePath}/resume.pdf`, "_blank")
                        }
                      >
                        Resume
                      </NavbarButton>
                    </DivMargin>
                    <DivMargin left={15} right={90}>
                      <NavbarButton
                        icon={FaEnvelope}
                        onClick={() => window.open(`mailto:${myEmail}`)}
                      >
                        Email
                      </NavbarButton>
                    </DivMargin>
                  </DivSection>
                </Col>
              )}
            </Row>
          </DivPageContainer>
        </Col>
      </Row>
    </DivContainer>
  );
};

const DivContainer = styled(motion.div)<{ bgColor: string; noFloat?: boolean }>`
  position: ${(props) => (props.noFloat ? undefined : "absolute")};
  top: 0;
  left: 0;
  width: 100%;
  margin: ${(props) => (props.noFloat ? undefined : "50px 0")};
  padding: ${(props) => (props.noFloat ? "50px 0" : undefined)};
  z-index: 1;
  background-color: ${(props) => props.bgColor};
`;

const DivSection = styled(DivFlex)`
  display: flex;
  align-items: center;
  height: 35px;
`;

const UlNavbarLinks = styled(UlNoBullets)`
  li {
    display: inline-block;
    margin-right: 40px;
  }
`;

export default Navbar;
