import React, { useContext, useEffect } from "react";
import { motion, useViewportScroll } from "framer-motion";
import styled from "styled-components/macro";
import Icon from "./Icon";
import { IoIosMenu } from "react-icons/io";
import { GlobalContext } from "./GlobalContextWrapper";
import { useBreakpoints } from "../hooks/useBreakpoints";

interface Props {
  color: string;
  hoverColor: string;
  visible: boolean;
}

const MenuIcon: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx = useContext(GlobalContext);
  const [onScreen, setOnScreen] = React.useState(false);
  const { scrollY } = useViewportScroll();
  const { isXl } = useBreakpoints();

  const variants = {
    hidden: {
      opacity: 0,
      translateY: "100px",
      transition: {
        duration: 0.1,
      },
    },
    visible: {
      opacity: 1,
      translateY: "0px",
      transition: {
        delay: 0.2,
        duration: 0.3,
      },
    },
  };

  useEffect(() => {
    determineIfShouldBeVisible(scrollY.get());
    const unsub = scrollY.onChange((yVal) => {
      determineIfShouldBeVisible(yVal);
    });
    return () => unsub();
  });

  const determineIfShouldBeVisible = (yVal: number) => {
    if (!isXl) {
      setOnScreenValue(true);
    } else if (checkIfScrolledPastNavbar(yVal)) {
      setOnScreenValue(true);
    } else {
      setOnScreenValue(false);
    }
  };

  const setOnScreenValue = (shouldBeOnScreen: boolean): void => {
    if (shouldBeOnScreen && !onScreen) {
      setOnScreen(true);
    } else if (!shouldBeOnScreen && onScreen) {
      setOnScreen(false);
    }
  };

  const checkIfScrolledPastNavbar = (yVal: number): boolean => {
    return yVal > 45;
  };

  return (
    <>
      <DivContainer
        variants={variants}
        initial={"hidden"}
        animate={onScreen ? "visible" : "hidden"}
        visible={props.visible}
        isXl={isXl}
      >
        <Icon
          icon={IoIosMenu}
          title={"Open menu"}
          size={50}
          color={props.color}
          hoverColor={props.hoverColor}
          onClick={() => globalCtx.setIsMenuOpen(true)}
        />
      </DivContainer>
    </>
  );
};

const DivContainer = styled(motion.div)<{ visible: boolean; isXl: boolean }>`
  cursor: pointer;
  position: fixed;
  right: ${(props) => (props.isXl ? 70 : 30)}px;
  top: ${(props) => (props.isXl ? 40 : 20)}px;
  z-index: 7;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

export default MenuIcon;
