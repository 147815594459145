import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components/macro";
import Icon from "./Icon";
import { BsChevronCompactDown } from "react-icons/bs";
import Palette from "../palette";

interface Props {
  visible: boolean;
  onClick?: () => void;
}

const ScrollIndicator: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={props.visible ? { opacity: 1 } : { opacity: 0 }}
      onClick={props.onClick}
    >
      <DivContainer
        initial={{ translateY: "0px", scale: 1 }}
        animate={{ translateY: "35px", scale: 1.25 }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 1.5,
          repeatDelay: 0.2,
        }}
        isClickable={!!props.onClick}
      >
        <Icon
          title={"Scroll for more"}
          icon={BsChevronCompactDown}
          color={Palette.white}
          size={55}
        />
      </DivContainer>
    </motion.div>
  );
};

const DivContainer = styled(motion.div)<{ isClickable: boolean }>`
  cursor: ${(props) => (props.isClickable ? "pointer" : undefined)};
  position: absolute;
  bottom: 80px;
  right: 50%;
  margin-right: -30px;
`;

export default ScrollIndicator;
