import styled from "styled-components/macro";

interface Props {
  margin?: number;
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
}

const DivMargin = styled.div<Props>`
    ${props => (props.margin ? `margin: ${props.margin}px;` : undefined)}
    ${props => (props.top ? `margin-top: ${props.top}px;` : undefined)}
    ${props => (props.bottom ? `margin-bottom: ${props.bottom}px;` : undefined)}
    ${props => (props.left ? `margin-left: ${props.left}px;` : undefined)}
    ${props => (props.right ? `margin-right: ${props.right}px;` : undefined)}
`;

export default DivMargin;
