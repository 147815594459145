import { motion } from "framer-motion";
import React from "react";
import Waypoint from "./Waypoint";

interface Props {
  children: any;
  distance?: number;
  up?: boolean;
  down?: boolean;
}

const FadeIn: React.FC<Props> = (props: Props): JSX.Element => {
  const [visible, setVisible] = React.useState(false);

  const fadeIn = {
    hidden: {
      opacity: 0,
      translateY: `${props.down ? "-" : ""}${props.distance || 0}px`,
    },
    visible: {
      opacity: 1,
      translateY: "0px",
      transition: {
        type: "tween",
        delay: 0.2,
        duration: 0.5,
      },
    },
  };

  return (
    <Waypoint onEnter={() => setVisible(true)}>
      <motion.div
        variants={fadeIn}
        initial={"hidden"}
        animate={visible ? "visible" : "hidden"}
      >
        {props.children}
      </motion.div>
    </Waypoint>
  );
};

export default FadeIn;
