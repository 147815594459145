import React from "react";
import { Col, Row } from "react-grid-system";
import DivMargin from "../../components/DivMargin";
import WorkListCard from "../../components/WorkListCard";
import { Content } from "../../content/content";
import styled from "styled-components/macro";

interface Props {}

const WorkItemsList: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <DivContainer>
      <Row justify={"center"}>
        {Content.workItems.map((item, index) => (
          <Col key={item.name} className={"work-item"} md={6} lg={4}>
            <DivMargin bottom={25}>
              <WorkListCard index={index} item={item} />
            </DivMargin>
          </Col>
        ))}
      </Row>
    </DivContainer>
  );
};

const DivContainer = styled.div`
  margin: 50px 20px 150px;

  .work-item {
    .work-card {
      margin: 0 12px;
    }
  }

  @media (min-width: 1px) and (max-width: 767px) {
    margin: 50px 5px 150px 20px;

    .work-item {
      .work-card {
        margin-left: 0;
      }
    }
  }
`;

export default WorkItemsList;
