import React from "react";
import WorkItemLayout from "../layouts/WorkItemLayout";
import { themeEso } from "../themes";
import { contentBasePath } from "../constants";
import { useBreakpoints } from "../hooks/useBreakpoints";
import Anchor from "../components/Anchor";
import SpecialText from "../components/SpecialText";
import { FaReact } from "react-icons/fa";

interface Props {}

const WorkEsoPage: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  return (
    <WorkItemLayout
      workItem={{
        designedByMe: true,
        theme: themeEso,
        logoSrc: `${contentBasePath}/images/eso-logo.jpg`,
        logoTitle: "Elder Scrolls LFG Logo",
        name: "Elder Scrolls LFG",
        year: "2020",
        webLink: "https://esolfg.com",
        description: (
          <>
            Elder Scrolls LFG (Looking for Group) is a companion web app for
            the&nbsp;
            <Anchor
              color={themeEso.link}
              hoverColor={themeEso.linkHover}
              href={"https://www.elderscrollsonline.com/en-us/home"}
              target={"_blank"}
            >
              Elder Scrolls Online MMO
            </Anchor>
            . It’s a matching engine that significantly improves and adds
            additional functionality beyond the existing in-game engine. It's
            purpose is to connect players, based on a rich set of criteria, who
            want to complete the same in-game activities. Users will add their
            in-game character's details to their account and fill out an
            activity wizard specifying what type of activity (e.g. group
            dungeons or raids) they want to complete. Once they're ready to
            proceed they join the&nbsp;
            <SpecialText>Queue</SpecialText>. When there are other users who
            want to complete the same activity, the matching engine will
            automatically invite them to join a virtual&nbsp;
            <SpecialText>Party</SpecialText>
            &nbsp;and continue filling in party positions until full. Once the
            party is full, the party leader invites them in-game to complete the
            content.
          </>
        ),
        splashSrc: `${contentBasePath}/images/eso-splash.jpg`,
        splashPosition: "top center",
        showcases: [
          {
            bgSrc: `${contentBasePath}/images/eso-mobile-splash.jpg`,
            phoneOne: {
              id: "screen-1",
              src: `${contentBasePath}/images/eso-mobile-1.png`,
              title: "Screenshot of Home screen on mobile",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeEso.deviceTrim,
            },
            phoneTwo: {
              id: "screen-2",
              src: `${contentBasePath}/images/eso-mobile-2.png`,
              title: "Screenshot of Activity Wizard screen on mobile",
              animate: breakpoints.isMd,
              rotateDirection: "left",
              trimColor: themeEso.deviceTrim,
            },
            phoneThree: {
              id: "screen-3",
              src: `${contentBasePath}/images/eso-mobile-3.png`,
              title: "Screenshot of LFG screen on mobile",
              animate: breakpoints.isMd,
              rotateDirection: "right",
              trimColor: themeEso.deviceTrim,
            },
          },
        ],
        infoBlocks: [
          {
            cards: [
              {
                heading: "Serverless Architecture",
                body: (
                  <>
                    <SpecialText
                      src={`${contentBasePath}/images/lambda-logo.svg`}
                      href={"https://aws.amazon.com/lambda/"}
                    >
                      Lambda
                    </SpecialText>
                    &nbsp;functions and&nbsp;
                    <SpecialText
                      src={`${contentBasePath}/images/ddb-logo.svg`}
                      href={"https://aws.amazon.com/dynamodb/"}
                    >
                      DynamoDB
                    </SpecialText>
                    &nbsp;tables have been used for all stateless transactions
                    and a&nbsp;
                    <SpecialText
                      icon={FaReact}
                      iconColor={"#61DAFB"}
                      href={"https://reactjs.org/"}
                    >
                      React
                    </SpecialText>
                    &nbsp;SPA sitting behind a CloudFront distribution was used
                    for the frontend; the app is cheap to host, performant and
                    scales almost infinitely. Some features do however require
                    state to be persisted, to satisfy this requirement I’m
                    running an optimized&nbsp;
                    <SpecialText
                      src={
                        "https://raw.githubusercontent.com/fastify/graphics/91e8a3d4754807de3b69440f66c72a737a5fde94/fastify-white-portrait.svg"
                      }
                      href={"https://www.fastify.io/"}
                    >
                      Fastify
                    </SpecialText>
                    &nbsp;server instance as a containerized AWS ECS service.
                  </>
                ),
              },
              {
                heading: "Authentication and Security",
                body: (
                  <>
                    The site's secure APIs are protected by stateless JWT based
                    authentication and access control. For security reasons, the
                    user's client must support at least TLSv1.1 to
                    connect.&nbsp;
                    <Anchor
                      color={themeEso.link}
                      hoverColor={themeEso.linkHover}
                      href={"https://letsencrypt.org/"}
                    >
                      Let’s Encrypt’s
                    </Anchor>
                    &nbsp;framework was used to generate free, valid wildcard
                    SSL certs so that all data is fully protected in transit.
                  </>
                ),
              },
              {
                heading: "Public Forms",
                body: (
                  <>
                    To prevent spam and bot account, all public forms and
                    backing APIs (e.g. user registration and support requests)
                    are protected from automated submissions using&nbsp;
                    <Anchor
                      color={themeEso.link}
                      hoverColor={themeEso.linkHover}
                      href={"https://developers.google.com/recaptcha/docs/v3"}
                    >
                      Google reCAPTCHA v3
                    </Anchor>
                    .
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/eso-browser-1.jpg`,
            imgTitle: "Screenshot of Home screen on desktop",
          },
          {
            cards: [
              {
                heading: "Real-time User Interactions",
                body: (
                  <>
                    Party members can update their activity settings, invite,
                    kick and chat with other party members all in real time.
                    I've integrated the front-end with the&nbsp;
                    <Anchor
                      color={themeEso.link}
                      hoverColor={themeEso.linkHover}
                      href={
                        "https://developer.mozilla.org/en-US/docs/Web/API/Server-sent_events/Using_server-sent_events"
                      }
                      target={"_blank"}
                    >
                      Server Sent Events (SSE)
                    </Anchor>
                    &nbsp;browser API; when a user joins a group they connect to
                    an&nbsp;
                    <SpecialText>EventStream</SpecialText> hosted in the&nbsp;
                    <SpecialText>Group Service Engine</SpecialText>. Every time
                    a user interacts with the party an event is posted to this
                    stream and propagated to all users in the party which
                    updates their browser with the changes instantly,
                    eliminating the need to constantly poll.
                  </>
                ),
              },
              {
                heading: "Error Monitoring and Alerting",
                body: (
                  <>
                    I’ve fully integrated all frontend and backend components
                    with the vendor&nbsp;
                    <SpecialText
                      src={`${contentBasePath}/images/sentry-logo.svg`}
                      href={"https://sentry.io"}
                    >
                      Sentry
                    </SpecialText>
                    , which monitors for errors that occur and records the steps
                    leading up to them. Each error will generate an alert
                    directly to my email address so I am aware of and can track
                    all of the issues my users encounter.
                  </>
                ),
              },
              {
                heading: "Payment Gateway Integration",
                body: (
                  <>
                    While this app is provided free of charge to my users, I’ve
                    integrated with the&nbsp;
                    <SpecialText
                      src={`${contentBasePath}/images/stripe-logo.svg`}
                      href={"https://stripe.com/docs/js"}
                    >
                      Stripe
                    </SpecialText>
                    &nbsp;payment gateway in addition to PayPal in order to
                    accept secure credit card donations.
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/eso-browser-2.jpg`,
            imgTitle: "Screenshot of Party screen on desktop",
          },
        ],
      }}
    />
  );
};

export default WorkEsoPage;
