import React from "react";
import Palette from "../../palette";
import SpanRobotoFont from "../../components/SpanRobotoFont";
import DivMargin from "../../components/DivMargin";
import DivTextAlign from "../../components/DivTextAlign";
import FadeInText from "../../components/FadeInText";
import DivFixed from "../../components/DivFixed";
import DivFlex from "../../components/DivFlex";

interface Props {}

const BonusSection: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <DivFlex justify={"center"}>
        <DivFixed width={"350px"}>
          <DivTextAlign align={"center"}>
            <DivMargin bottom={75}>
              <SpanRobotoFont color={Palette.white} size={"30px"}>
                <FadeInText>
                  Here, you deserve a break. I won't tell anyone.
                </FadeInText>
              </SpanRobotoFont>
            </DivMargin>
          </DivTextAlign>
        </DivFixed>
      </DivFlex>
      <iframe
        title={"FreeRider"}
        width="100%"
        height="450"
        src="https://www.freeriderhd.com/game/756489-smooth-hill-jumps?embedded=true"
        frameBorder="0"
        allowFullScreen
        style={{ border: "border: 1px solid #000" }}
        scrolling="no"
      />
    </>
  );
};

export default BonusSection;
