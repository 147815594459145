import React from "react";
import WorkItemLayout from "../layouts/WorkItemLayout";
import { themeZenb } from "../themes";
import { contentBasePath } from "../constants";
import Anchor from "../components/Anchor";

interface Props {}

const WorkZenbPage: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <WorkItemLayout
      workItem={{
        theme: themeZenb,
        logoSrc: `${contentBasePath}/images/zenb-logo.png`,
        logoTitle: "ZENB: Plant Based Pasta",
        name: "ZENB Global",
        year: "2022",
        webLink: "https://zenbglobal.com",
        description: (
          <>
            Inspired by Zenbu — the Japanese expression for whole — ZENB
            (pronounced Zen-B) products are made with people and the planet in
            mind to help you live a wholesome, happy life. Recently, ZENB has
            partnered with professional football club FC Barcelona as the first
            Official Gluten-Free Food Partner of the club in the US, UK and
            Japan. This purpose of this project was to create a single global
            page where visitors who don't have a region specific landing page
            can learn about the partnership.
          </>
        ),
        splashSrc: `${contentBasePath}/images/zenb-global-splash.jpg`,
        splashPosition: "top center",
        showcases: [],
        infoBlocks: [
          {
            cards: [
              {
                heading: "Geo-redirects",
                body: (
                  <>
                    Visitors viewing from the United States, United Kingdom and
                    Japan are seamlessly re-routed to their respective regional
                    landing page using AWS CloudFront Functions. Based on the
                    viewer's country of origin a redirect occurs in the edge
                    node closest to the user for best in class performance.
                  </>
                ),
              },
              {
                heading: "Lighthouse",
                body: (
                  <>
                    Increasingly,&nbsp;
                    <Anchor
                      color={themeZenb.link}
                      hoverColor={themeZenb.linkHover}
                      href={
                        "https://chrome.google.com/webstore/detail/lighthouse/blipmdconlkpinefehnmjammfjpmpbjk?hl=en"
                      }
                    >
                      Google Lighthouse
                    </Anchor>
                    &nbsp; is being used as a means to test the quality and
                    performance of websites. Great care was taken to push
                    performance to the limits while maintain quality, resulting
                    in nearly(!) perfect scores across the board.
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        style={{
                          width: "auto",
                          height: "150px",
                          marginTop: "20px",
                        }}
                        alt={"ZENB Global Lighthouse scores"}
                        src={
                          "https://content.tylerburke.dev/images/zenb-global-lighthouse.jpg"
                        }
                      ></img>
                    </div>
                  </>
                ),
              },
            ],
            imgSrc: `${contentBasePath}/images/zenb-global-browser-1.jpg`,
            imgTitle: "Screenshot of ZENB Global website",
          },
        ],
      }}
    />
  );
};

export default WorkZenbPage;
