import { useHistory } from "react-router-dom";

export function useHistoryState<T>(): T {
  const history = useHistory();
  if (history && history.location && history.location.state) {
    return history.location.state as T;
  } else {
    return {} as T;
  }
}
