import React from "react";
import styled from "styled-components/macro";
import { MobileShowcaseModel } from "../models/mobileShowcase.model";
import PhoneMockup from "./PhoneMockup";
import DivFlex from "./DivFlex";
import DivMargin from "./DivMargin";
import { BreakpointsModel, useBreakpoints } from "../hooks/useBreakpoints";

interface Props {
  showcase: MobileShowcaseModel;
}

const MobileShowcase: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  const calcPhoneHorizontalMargin = (): number => {
    if (breakpoints.isLg) {
      return 90;
    } else if (!breakpoints.isLg && breakpoints.isMd) {
      return 25;
    } else {
      return 0;
    }
  };

  const calcPhoneVerticalMargin = (): number => {
    if (!breakpoints.isMd) {
      return 25;
    } else {
      return 0;
    }
  };

  const renderPhones = () => (
    <>
      <DivFlex justify={"center"} align={"center"}>
        <PhoneMockup phone={props.showcase.phoneOne} />
      </DivFlex>
      <DivMargin
        left={calcPhoneHorizontalMargin()}
        right={calcPhoneHorizontalMargin()}
        top={calcPhoneVerticalMargin()}
        bottom={calcPhoneVerticalMargin()}
      >
        <DivFlex justify={"center"} align={"center"}>
          <PhoneMockup phone={props.showcase.phoneTwo} />
        </DivFlex>
      </DivMargin>
      <DivFlex justify={"center"} align={"center"}>
        <PhoneMockup phone={props.showcase.phoneThree} />
      </DivFlex>
    </>
  );

  return (
    <>
      {breakpoints.isMd ? (
        <DivContainer bgSrc={props.showcase.bgSrc} breakpoints={breakpoints}>
          <DivFlex justify={"center"} align={"center"}>
            {renderPhones()}
          </DivFlex>
        </DivContainer>
      ) : (
        renderPhones()
      )}
    </>
  );
};

const DivContainer = styled.div<{
  bgSrc: string;
  breakpoints: BreakpointsModel;
}>`
  height: 475px;
  background-image: url("${(props) => props.bgSrc}");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  ${(props) =>
    props.breakpoints.isMd
      ? `
  #screen-1 {
    position: relative;
    top: -125px;
  }

  #screen-2 {
    position: relative;
    top: 25px;
  }

  #screen-3 {
    position: relative;
    top: -125px;
  }
  `
      : undefined};
`;

export default MobileShowcase;
