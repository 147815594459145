import Portal from "@material-ui/core/Portal";
import React from "react";
import styled from "styled-components/macro";
import { useBreakpoints } from "../hooks/useBreakpoints";
import FullScreenImage from "./FullScreenImage";

interface Props {
  frameColor: string;
  screenSrc: string;
  animateDir: "right" | "left";
}

const BrowserMockup: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const calcImgWidth = (): number => {
    if (breakpoints.isLg) {
      return 600;
    } else if (!breakpoints.isLg && breakpoints.isMd) {
      return 545;
    } else if (!breakpoints.isMd && breakpoints.isSm) {
      return 545;
    } else {
      return 300;
    }
  };

  return (
    <>
      {isFullScreen && (
        <Portal>
          <FullScreenImage
            src={props.screenSrc}
            onClose={() => setIsFullScreen(false)}
            animateDir={props.animateDir}
          />
        </Portal>
      )}
      <DivContainer
        canActivate={breakpoints.isLg}
        frameColor={props.frameColor}
        width={calcImgWidth()}
        onClick={() => (breakpoints.isSm ? setIsFullScreen(true) : undefined)}
      >
        <DivTopBar frameColor={props.frameColor}>
          <DivButtonNode />
          <DivButtonNode />
          <DivButtonNode />
        </DivTopBar>
        <ImgScreen isFullScreen={isFullScreen} src={props.screenSrc} />
        <DivBottomBar frameColor={props.frameColor} />
      </DivContainer>
    </>
  );
};

const DivContainer = styled.div<{
  canActivate: boolean;
  frameColor: string;
  width: number;
}>`
  width: ${(props) => props.width}px;
  cursor: ${(props) => (props.canActivate ? "zoom-in" : undefined)};
`;

const DivTopBar = styled.div<{ frameColor: string }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.frameColor};
  height: 30px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const DivButtonNode = styled.div`
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.55);
  width: 10px;
  height: 10px;
  margin-right: 10px;
  &:first-of-type {
    margin-left: 15px;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const DivBottomBar = styled.div<{ frameColor: string }>`
  background-color: ${(props) => props.frameColor};
  height: 25px;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const ImgScreen = styled.img<{ isFullScreen: boolean }>`
  width: 100%;
  height: 550px;
  object-fit: cover;
  object-position: top;
`;

export default BrowserMockup;
