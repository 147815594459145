import React, { RefObject, useContext, useEffect } from "react";
import MenuModal from "../components/MenuModal";
import MenuIcon from "../components/MenuIcon";
import TintedWindows from "../components/TintedWindows";
import { Col, Container, Row } from "react-grid-system";
import Footer from "../components/Footer";
import { GlobalContext } from "../components/GlobalContextWrapper";
import Navbar from "../components/Navbar";
import { useBreakpoints } from "../hooks/useBreakpoints";
import Palette from "../palette";
import { useHistoryState } from "../hooks/useHistoryState";
import { scrollIntoView } from "../functions/scrollTo";
import DivPageContainer from "../components/DivPageContainer";

interface Props {
  children?: any;
}

const MainLayout: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx = useContext(GlobalContext);
  const breakpoints = useBreakpoints();
  const state = useHistoryState<{ ref: RefObject<HTMLDivElement> }>();

  useEffect(() => {
    if (state.ref) {
      scrollIntoView(state.ref);
    }
  }, [state.ref]);

  return (
    <>
      {globalCtx.isMenuOpen && (
        <MenuModal highlightColor={Palette.secondaryLight} />
      )}
      <MenuIcon
        visible={!globalCtx.isMenuOpen}
        color={Palette.white}
        hoverColor={Palette.secondaryLight}
      />
      {globalCtx.showTint && breakpoints.isMd && <TintedWindows />}
      <DivPageContainer>
        {breakpoints.isXl && (
          <Navbar
            bgColor={"transparent"}
            highlightColor={Palette.secondaryLight}
          />
        )}
        {props.children}
        <Container fluid>
          <Row>
            <Col>
              <Footer
                bgColor={Palette.footer}
                highlightColor={Palette.tertiary}
                highlightHoverColor={Palette.secondaryLight}
              />
            </Col>
          </Row>
        </Container>
      </DivPageContainer>
    </>
  );
};

export default MainLayout;
