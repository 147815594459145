import React, { useEffect, useRef } from "react";
import { useIntersection } from "react-use";

interface Props {
  children: any;
  onChange?: (isVisible: boolean) => void;
  onEnter?: () => void;
  onExit?: () => void;
  threshold?: number;
}

const Waypoint: React.FC<Props> = (props: Props): JSX.Element => {
  const wpRef = useRef<HTMLDivElement>(null);
  const intersectionObserver = useIntersection(wpRef, {
    threshold: props.threshold || 0,
  });

  useEffect(() => {
    const isIntersectingAtThreshold = () => {
      return (
        intersectionObserver?.isIntersecting! &&
        intersectionObserver?.intersectionRatio! >= (props.threshold || 0)
      );
    };
    if (props.onChange) {
      props.onChange(isIntersectingAtThreshold());
    }
    if (props.onEnter && isIntersectingAtThreshold()) {
      props.onEnter();
    }
    if (props.onExit && !isIntersectingAtThreshold()) {
      props.onExit();
    }
  }, [intersectionObserver, props]);

  return <span ref={wpRef}>{props.children}</span>;
};

export default Waypoint;
