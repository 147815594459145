import React from "react";
import Waypoint from "./Waypoint";
import { Col, Row } from "react-grid-system";
import ServiceCard from "./ServiceCard";
import { Content } from "../content/content";
import MotionSlideConnectorRight from "./MotionSlideConnectorRight";
import ConnectorCircle from "./ConnectorCircle";
import { useBreakpoints } from "../hooks/useBreakpoints";

const FrontendServices: React.FC = (): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const { isLg } = useBreakpoints();

  return (
    <Waypoint onEnter={() => setVisible(true)} threshold={0.9}>
      <Row>
        <Col lg={6}>
          <ServiceCard service={Content.services.frontend} />
        </Col>
        {isLg && (
          <Col>
            <div className={"flex align-center h-100p w-middle-iceberg mt-25"}>
              <MotionSlideConnectorRight
                visible={visible}
                className={"bg-tertiary h-3"}
                transition={{ duration: 1.5 }}
              />
              <ConnectorCircle transition={{ delay: 0.9 }} visible={visible} />
            </div>
          </Col>
        )}
      </Row>
    </Waypoint>
  );
};

export default FrontendServices;
