import React, { useEffect } from "react";
import { Col, Row } from "react-grid-system";
import HeadingOverlined from "../../components/HeadingOverlined";
import SpanRobotoFont from "../../components/SpanRobotoFont";
import DivMargin from "../../components/DivMargin";
import Palette from "../../palette";
import Button from "../../components/Button";
import styled from "styled-components/macro";
import PhoneMockup from "../../components/PhoneMockup";
import { motion, useAnimation } from "framer-motion";
import Waypoint from "../../components/Waypoint";
import DivFixed from "../../components/DivFixed";
import { contentBasePath } from "../../constants";
import { BreakpointsModel, useBreakpoints } from "../../hooks/useBreakpoints";
import { useHistory } from "react-router-dom";
import FadeInText from "../../components/FadeInText";

interface Props {}

const FeaturedWorkSection: React.FC<Props> = (props: Props): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const animateBg = useAnimation();
  const breakpoints = useBreakpoints();
  const history = useHistory();

  useEffect(() => {
    if (visible) {
      animateBg.start({ opacity: 1 }).then(() => {
        animateBg.start({
          translateX: "0%",
          transition: {
            type: "tween",
            ease: [0.5, 0.8, 0.9, 1],
            duration: 1,
          },
        });
      });
    } else {
      animateBg.set({
        opacity: 0,
        translateX: "5.5%",
        transition: { delay: 0, duration: 0 },
      });
    }
  }, [animateBg, visible]);

  const calcHeadingSize = (): number => {
    if (!breakpoints.isSm) {
      return 45;
    } else {
      return 100;
    }
  };

  const calcTextContainerMargins = (): string => {
    if (!breakpoints.isXl && breakpoints.isMd) {
      return "0 0 150px 10px";
    } else if (breakpoints.isXs) {
      return "0 0 50px 10px";
    } else {
      return "";
    }
  };

  const goToEsoPage = () => {
    history.push("/work/esolfg");
  };

  const renderPhones = () => (
    <DivPhoneContainer isMd={breakpoints.isMd}>
      <PhoneMockup
        onClick={() => goToEsoPage()}
        phone={{
          id: "eso-screen-1",
          src: `${contentBasePath}/images/eso-mobile-1.png`,
          title: "Screenshot of ESO LFG homepage",
          animate: breakpoints.isMd,
          rotateDirection: "right",
          trimColor: "#012a47",
        }}
      />
      <PhoneMockup
        onClick={() => goToEsoPage()}
        phone={{
          id: "eso-screen-2",
          src: `${contentBasePath}/images/eso-mobile-2.png`,
          title: "Screenshot of ESO LFG activity wizard",
          animate: breakpoints.isMd,
          rotateDirection: "left",
          trimColor: "#012a47",
        }}
      />
      <PhoneMockup
        onClick={() => goToEsoPage()}
        phone={{
          id: "eso-screen-3",
          src: `${contentBasePath}/images/eso-mobile-3.png`,
          title: "Screenshot of ESO LFG party screen",
          animate: breakpoints.isMd,
          rotateDirection: "right",
          trimColor: "#012a47",
        }}
      />
    </DivPhoneContainer>
  );

  return (
    <Row>
      <Col xl={4.5}>
        <DivTextContainer
          breakpoints={breakpoints}
          margins={calcTextContainerMargins()}
        >
          <div>
            <HeadingOverlined fontSize={calcHeadingSize()}>
              Featured
              <br />
              work
            </HeadingOverlined>
            <DivMargin bottom={5}>
              <SpanRobotoFont size={"20px"} color={Palette.white}>
                Elder Scrolls LFG
              </SpanRobotoFont>
            </DivMargin>
            <DivMargin bottom={25}>
              <DivFixed width={breakpoints.isMd ? "350px" : "200px"}>
                <SpanRobotoFont
                  size={"20px"}
                  color={Palette.white}
                  weight={"thin"}
                >
                  <FadeInText>
                    A fully featured activity finder / group matching service
                    for The Elder Scrolls Online MMO.
                  </FadeInText>
                </SpanRobotoFont>
              </DivFixed>
            </DivMargin>
            <Button onClick={() => history.push("/work")}>View all work</Button>
          </div>
        </DivTextContainer>
      </Col>
      <Col>
        <Waypoint onEnter={() => setVisible(true)}>
          <DivShowcaseContainer>
            {breakpoints.isMd && (
              <>
                <DivEsoBgContainer>
                  <DivEsoBg
                    initial={{ opacity: 0 }}
                    animate={animateBg}
                    breakpoints={breakpoints}
                  />
                </DivEsoBgContainer>
                {renderPhones()}
              </>
            )}
            {!breakpoints.isMd && <>{renderPhones()}</>}
          </DivShowcaseContainer>
        </Waypoint>
      </Col>
    </Row>
  );
};

const DivShowcaseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivPhoneContainer = styled.div<{ isMd: boolean }>`
  display: ${(props) => (props.isMd ? "flex" : undefined)};
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

  ${(props) =>
    props.isMd
      ? `
    #eso-screen-1 {
    position: relative;
    top: -100px;
    }

    #eso-screen-2 {
      position: relative;
      top: 75px;
      margin: 0 50px;
    }
  
    #eso-screen-3 {
      position: relative;
      top: -100px;
    }`
      : `
    #eso-screen-1 {
      margin-top: 35px;
    }
    
    #eso-screen-2 {
      margin: 50px 0;
    }
  `}
`;

const DivEsoBgContainer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  min-height: 525px;
  padding: 20px 0 20px 20px;
  margin-left: -20px;
`;

const DivEsoBg = styled(motion.div)<{ breakpoints: BreakpointsModel }>`
  height: 100%;
  width: 100%;
  border-top-left-radius: ${(props) => (!props.breakpoints.isSm ? 0 : 15)}px;
  border-bottom-left-radius: ${(props) => (!props.breakpoints.isSm ? 0 : 15)}px;
  background-image: url("${`${contentBasePath}/images/esolfg-bg.jpg`}");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.35);
  background-blend-mode: overlay;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.55);
  z-index: 1;
`;

const DivTextContainer = styled.div<{
  breakpoints: BreakpointsModel;
  margins: string;
}>`
  position: relative;
  left: ${(props) => (props.breakpoints.isMd ? 50 : 0)}px;
  display: flex;
  justify-content: ${(props) =>
    props.breakpoints.isXxl ? "flex-end" : "flex-start"};
  margin: ${(props) => props.margins};
  align-items: center;
  z-index: 3;
`;

export default FeaturedWorkSection;
