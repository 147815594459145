import React from "react";
import styled from "styled-components/macro";
import { IconType } from "react-icons";
import Icon from "./Icon";
import AnchorNoStyle from "./AnchorNoStyle";
import { BreakpointsModel, useBreakpoints } from "../hooks/useBreakpoints";

interface Props {
  icon?: IconType;
  src?: string;
  color?: string;
  bgColor?: string;
  hoverColor?: string;
  iconColor?: string;
  title?: string;
  children: string | JSX.Element;
  href?: string;
}

const SpecialText: React.FC<Props> = (props: Props): JSX.Element => {
  const breakpoints = useBreakpoints();

  return (
    <SpanContainer
      color={props.color || "white"}
      bgColor={props.bgColor || "#707070"}
      hoverColor={props.bgColor || "#606060"}
      isClickable={!!props.href}
      breakpoints={breakpoints}
    >
      <AnchorNoStyle href={props.href} target={"_blank"}>
        {props.icon && (
          <Icon
            icon={props.icon}
            title={props.title || ""}
            color={props.iconColor || "white"}
            size={breakpoints.isSm ? 15 : 12}
          />
        )}
        {!props.icon && props.src && (
          <img src={props.src} alt={props.title} title={props.title} />
        )}
        {props.children}
      </AnchorNoStyle>
    </SpanContainer>
  );
};

export default SpecialText;

const SpanContainer = styled.span<{
  bgColor: string;
  hoverColor: string;
  color: string;
  isClickable: boolean;
  breakpoints: BreakpointsModel;
}>`
  display: inline-block;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  padding: 0 5px;
  width: fit-content;
  border-radius: 4px;
  cursor: ${(props) => (props.isClickable ? "pointer" : undefined)};
  margin-bottom: 2px;

  &:hover {
    background-color: ${(props) =>
      props.isClickable ? props.hoverColor : undefined};
  }

  .react-icon {
    margin-right: 3px;
    display: inline-block;
  }

  img {
    margin-right: 3px;
    width: auto;
    height: ${(props) => (props.breakpoints.isSm ? 15 : 12)}px;
  }
`;
