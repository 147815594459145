import styled from "styled-components/macro";

const AnchorNoStyle = styled.a`
  text-decoration-skip-ink: inherit;
  color: inherit;
  cursor: inherit;
  text-decoration: inherit;
  &:hover {
    outline: 0;
  }
  &:focus {
    outline: 0;
  }
  &:active {
    outline: 0;
  }
`;

export default AnchorNoStyle;
