import React, { useContext } from "react";
import MainLayout from "../layouts/MainLayout";
import { Col, Container, Row } from "react-grid-system";
import Waypoint from "../components/Waypoint";
import DivAbstractLandscape from "../components/DivAbstractLandscape";
import { contentBasePath } from "../constants";
import { GlobalContext } from "../components/GlobalContextWrapper";
import { useBreakpoints } from "../hooks/useBreakpoints";
import WorkItemsList from "./sections/WorkItemsList";
import DivTextAlign from "../components/DivTextAlign";
import SpanRobotoFont from "../components/SpanRobotoFont";
import Palette from "../palette";
import FadeInText from "../components/FadeInText";
import DivFlex from "../components/DivFlex";
import Button from "../components/Button";
import CentredUnderlinedText from "../components/CentredUnderlinedText";
import DivMargin from "../components/DivMargin";
import SpanPlayfairFont from "../components/SpanPlayfairFont";
import { goToAnchor } from "../functions/goToAnchor.fn";
import { useHistory } from "react-router-dom";
import FadeIn from "../components/FadeIn";

interface Props {}

const WorkPage: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx = useContext(GlobalContext);
  const breakpoints = useBreakpoints();
  const history = useHistory();

  return (
    <MainLayout>
      <Container fluid>
        <Row>
          <Col>
            <Waypoint
              onChange={(isVisible: boolean) => {
                globalCtx.setShowTint(() => {
                  return !isVisible;
                });
              }}
              threshold={0.2}
            >
              <div ref={globalCtx.pageRefs.top}>
                <DivAbstractLandscape
                  viewHeight={100}
                  bgPos={"bottom"}
                  imgSrc={`${contentBasePath}/images/abstract-landscape3.jpg`}
                >
                  <DivFlex
                    justify={"center"}
                    align={"center"}
                    style={{ height: "100%" }}
                  >
                    <DivTextAlign align={"center"}>
                      <FadeIn down distance={25}>
                        <SpanPlayfairFont
                          color={Palette.white}
                          size={!breakpoints.isMd ? "35px" : "60px"}
                        >
                          Web apps that <br />
                          just work
                        </SpanPlayfairFont>
                      </FadeIn>
                    </DivTextAlign>
                  </DivFlex>
                </DivAbstractLandscape>
              </div>
            </Waypoint>
          </Col>
        </Row>
        <DivMargin top={-200}>
          <Row justify={"center"}>
            <Col xs={12} xxl={10}>
              <WorkItemsList />
            </Col>
          </Row>
          <Row>
            <Col>
              <CentredUnderlinedText>
                <SpanRobotoFont color={Palette.white} size={"45px"}>
                  Have an idea?
                </SpanRobotoFont>
              </CentredUnderlinedText>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivMargin top={50} bottom={50}>
                <DivTextAlign align={"center"}>
                  <SpanRobotoFont
                    size={breakpoints.isSm ? "30px" : "25px"}
                    color={Palette.white}
                  >
                    <FadeInText>Tell me about your project</FadeInText>
                  </SpanRobotoFont>
                </DivTextAlign>
              </DivMargin>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivMargin bottom={250}>
                <DivFlex justify={"center"}>
                  <Button
                    onClick={() =>
                      goToAnchor(history, "/", globalCtx.pageRefs.contact)
                    }
                  >
                    Contact Me
                  </Button>
                </DivFlex>
              </DivMargin>
            </Col>
          </Row>
        </DivMargin>
      </Container>
    </MainLayout>
  );
};

export default WorkPage;
